.webshop-checkout__shippingmethods .wh-anchor {
  scroll-margin-top: 150px !important;
  top: -150px !important;
}

.webshop--ischeckoutpage .wh-anchor {
  scroll-margin-top: 150px;
  top: -100px;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__cost {
  display: none;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
  content: "gratis";
  color: #75b325;
  font-weight: 600;
}

html:not(.skw--offersin3) .skw-checkout__in3 {
  display: none;
}

.webshop-checkout__paymentmethod.webshop-checkout--isfree .webshop-checkout__costs {
  display: none;
}

html.wh-wrdauth--isloggedin .skw-checkout__loginline {
  display: none;
}

html:not(.skw--showcompanyfields) .skw-checkout__inlinecompanyfields {
  display: none;
}

html:not(.skw--showrepeatedcart) .skw-checkout__repeatedcart {
  display: none;
}

.wh-wrdauth__loginbutton {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
  cursor: pointer;
  background-color: #75b325;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #75b325;
  border-color: #75b325;
}
.wh-wrdauth__loginbutton:hover {
  background-color: #84c92a;
}
.wh-wrdauth__loginbutton:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

html:not(.skw--offerspraypay) .skw-product__spraypay,
html:not(.skw--isspraypay) .skw-checkout__spraypay {
  display: none;
}

.orderstatusblock__text #popup-spraypay {
  top: 0;
}