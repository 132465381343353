@charset "UTF-8";
.webshop--isproductpage .product__reviews small {
  color: #767676;
}

.widget-specialsalebanner {
  margin: 20px 0;
}
.widget-specialsalebanner img {
  box-shadow: var(--panel-box-shadow);
  width: 100%;
  max-width: 305px;
  height: auto;
  vertical-align: bottom;
}

@media (min-width: 320px) and (max-width: 375px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    height: 190px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 360px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 304px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 405px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 550px;
  }
}
@media (min-width: 1200px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 500px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 875px;
  }
}
html.dshops--3dimages .threedimagesactive .product__3dimages__grid {
  display: none !important;
}
html.dshops--3dimages .product__3dimages__grid .slick-slide {
  opacity: 1;
}
html.dshops--3dimages .dd-selected .dd-selected-image {
  display: none;
}
@media (max-width: 480px) {
  html.dshops--3dimages .dd-selected {
    padding: 4px 5px;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur,
html.dshops--3dimages .product__options .option__kleur-deuren,
html.dshops--3dimages .product__options .option__deur-kleur,
html.dshops--3dimages .product__options .option__kleur,
html.dshops--3dimages .product__options .option__deuren,
html.dshops--3dimages .product__options .option__glas-op-deuren,
html.dshops--3dimages .product__options .option__kleur-omranding,
html.dshops--3dimages .product__options .option__kleur-linkerdeur,
html.dshops--3dimages .product__options .option__kleur-rechterdeur,
html.dshops--3dimages .product__options .option__kleur-handgrepen {
  margin-bottom: 0;
}
html.dshops--3dimages .product__options .option__kast-kleur label,
html.dshops--3dimages .product__options .option__kleur-deuren label,
html.dshops--3dimages .product__options .option__deur-kleur label,
html.dshops--3dimages .product__options .option__kleur label,
html.dshops--3dimages .product__options .option__deuren label,
html.dshops--3dimages .product__options .option__glas-op-deuren label,
html.dshops--3dimages .product__options .option__kleur-omranding label,
html.dshops--3dimages .product__options .option__kleur-linkerdeur label,
html.dshops--3dimages .product__options .option__kleur-rechterdeur label,
html.dshops--3dimages .product__options .option__kleur-handgrepen label {
  margin-bottom: 0;
}
@media (min-width: 320px) and (max-width: 379px) {
  html.dshops--3dimages .product__options .option__kast-kleur .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur-deuren .col-sm-9,
  html.dshops--3dimages .product__options .option__deur-kleur .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur .col-sm-9,
  html.dshops--3dimages .product__options .option__deuren .col-sm-9,
  html.dshops--3dimages .product__options .option__glas-op-deuren .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur-omranding .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .col-sm-9,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .col-sm-9 {
    height: 30px;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container,
html.dshops--3dimages .product__options .option__kleur .dd-container,
html.dshops--3dimages .product__options .option__deuren .dd-container,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container {
  position: relative;
  z-index: 9;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-select {
  display: none;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options {
  display: block !important;
  position: inherit !important;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li {
  display: inline-block;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid #ccc;
  margin: 0 5px 5px 0;
  border-radius: 3px;
}
@media (min-width: 320px) and (max-width: 374px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option {
    margin: 0 5px 5px 0;
  }
}
@media (min-width: 375px) and (max-width: 420px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option {
    margin: 0 6px 4px 0;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 48px;
  width: 100%;
  margin-right: 0;
}
@media (min-width: 320px) and (max-width: 379px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option .dd-option-image {
    max-width: 30px;
    width: 100%;
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option .dd-option-image {
    max-width: 39px;
    width: 100%;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
@media (min-width: 375px) and (max-width: 420px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-omranding .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-handgrepen .dd-container .dd-options li:last-child .dd-option {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  html:not(.dshops--3dimages) .webshop-product__option .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }
  html:not(.dshops--3dimages) .webshop-product__option .col-sm-3 label {
    margin-bottom: 0 !important;
  }
  html:not(.dshops--3dimages) .webshop-product__option .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.webshop--isproductpage .bg-light {
  background-color: #fff !important;
}
.webshop--isproductpage .webshop-products--ordering-container {
  display: none;
}
.webshop--isproductpage .slick-prev {
  left: -1px;
  z-index: 1;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 40px;
  border-radius: 0 50px 50px 0;
}
.webshop--isproductpage .slick-prev:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f104"; /* fa-angle-left */
  color: #fff;
}
.webshop--isproductpage .slick-next {
  right: 0;
  z-index: 1;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 40px;
  border-radius: 50px 0 0 50px;
}
.webshop--isproductpage .slick-next:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f105"; /* fa-angle-right */
  color: #fff;
}

.tab-pane:before {
  display: block;
  content: " ";
  height: 250px; /* Give height of your fixed element */
  margin-top: -250px; /* Give negative margin of your fixed element */
  visibility: hidden;
}

.tab-content > .tab-pane {
  overflow: hidden;
}

.webshop-product__title {
  margin-bottom: 0;
}

.webshop-product__title + * {
  margin-top: 10px;
}

.webshop-product__rating,
.webshop-product__title + .productitem__rating {
  margin-top: 0;
}

.webshop-product__rating + *,
.webshop-product__title + .productitem__rating + * {
  margin-top: 10px;
}

/*
.webshop-product__title
.webshop-product__rating
.webshop-product__keypoints
*/
.product__options h1 {
  font-size: 28px;
}
.product__options .btn-primary {
  background-color: #75b325;
  border-color: #75b325;
  color: #fff !important;
}
.product__options .webshop-product__option label {
  font-size: 14px;
}

.product_options_price {
  padding: 15px;
  border: 1px solid #e8dbbe;
  background-color: #faf6ed;
  margin: 10px 0 0 0;
  color: #000;
}

#popup-in3 {
  color: #337ab7;
  cursor: pointer;
  top: -5px;
  position: relative;
  font-size: 18px;
}

html:not(.skw--offersin3) .skw-product__payin3 {
  display: none;
}

.skw-product__payin3 {
  font-size: 14px;
}
.skw-product__payin3 i {
  color: #708788;
}

#collapse-payin3 .checkmarks li {
  font-size: 14px;
  padding-left: 30px;
}
#collapse-payin3 .checkmarks li:before {
  font-size: 16px;
}

#product__images__preview__container {
  overflow: hidden;
  max-height: 640px;
}
@media (min-width: 1200px) {
  #product__images__preview__container {
    min-height: 500px;
  }
  #product__images__preview__container .slick-prev,
  #product__images__preview__container .slick-next {
    opacity: 0;
    transition: all 0.5s ease;
  }
  #product__images__preview__container:hover .slick-prev,
  #product__images__preview__container:hover .slick-next {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.product__image img {
  max-height: 100px;
  width: auto;
}
@media (max-width: 1200px) {
  .product__image img {
    max-height: 70px;
  }
}
@media (max-width: 767px) {
  .product__image img {
    max-height: 54px;
  }
}
.product__image:hover {
  cursor: pointer;
}

.product__images {
  position: relative;
}
.product__images .badge-danger {
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 1;
  background-color: #ff0000;
  border-radius: 0;
  font-size: 1.5em;
  font-weight: 400;
  text-transform: uppercase;
  padding: 8px 17px;
}
@media (max-width: 480px) {
  .product__images .badge-danger {
    font-size: 1em;
  }
}
@media (max-width: 992px) {
  .product__images {
    padding: 0;
  }
}
.product__images .product_ribbon {
  position: absolute;
  top: 50px;
  left: 30px;
  z-index: 1;
}
@media (max-width: 765px) {
  .product__images .product_ribbon {
    top: 0;
    left: 0;
  }
  .product__images .product_ribbon img {
    max-width: 200px;
    height: auto;
  }
}

.product__images__grid,
.product__3dimages__grid {
  margin: 20px 0;
  max-height: 100px;
  padding: 0;
}
@media (max-width: 480px) {
  .product__images__grid,
  .product__3dimages__grid {
    margin: 0;
  }
}
.product__images__grid .slick-slide,
.product__3dimages__grid .slick-slide {
  margin-right: 10px;
}

.product__images__grid .slick-slide {
  opacity: 0.6;
}
.product__images__grid .slick-slide.slick-current {
  opacity: 1;
}
.product__images__grid .slick-slide.slick-current img {
  border: 2px solid #000000;
}

.slick-dotted.slick-slider {
  margin-bottom: 40px;
}

.product__discountslogan {
  margin-top: 15px;
  color: #75b325;
  font-weight: 700;
  text-align: right;
}

#kt-configurator,
#waterbed-config {
  position: relative;
  padding-top: 40px;
  background: url(../../web/img/visualcue-down.png) transparent 50% 0% no-repeat;
}
#kt-configurator p, #kt-configurator .promoimages,
#waterbed-config p,
#waterbed-config .promoimages {
  padding-top: 5px;
}
#kt-configurator:before, #kt-configurator:after,
#waterbed-config:before,
#waterbed-config:after {
  content: "";
  display: block;
  border-top: 1px solid #b7aeab;
  position: absolute;
  top: 0;
}
#kt-configurator:before,
#waterbed-config:before {
  left: 15px;
  right: calc(50% + 74px);
}
#kt-configurator:after,
#waterbed-config:after {
  left: calc(50% + 74px);
  right: 15px;
}

#waterbed-config {
  margin-top: 20px;
}
#waterbed-config:before {
  left: 0;
}
#waterbed-config:after {
  right: 0;
}
#waterbed-config .carousel-item > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
#waterbed-config .carousel-indicators {
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 5px 0;
}
#waterbed-config .carousel-indicators > li {
  text-indent: 0;
  width: 20%;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  position: relative;
  height: auto;
}
#waterbed-config .carousel-indicators > li:before {
  display: none;
}
#waterbed-config .carousel-indicators > li:after {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
  top: 0;
  height: auto;
}
#waterbed-config .carousel-indicators > li > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#waterbed-config .carousel-indicators > li:hover, #waterbed-config .carousel-indicators > li.active {
  opacity: 1;
}
@media (max-width: 991px) {
  #waterbed-config .carousel-indicators {
    display: none;
  }
}
#waterbed-config .carousel-control-prev {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001));
  background-repeat: repeat-x;
}
#waterbed-config .carousel-control-prev > .fa, #waterbed-config .carousel-control-prev > .far {
  font-size: 30px;
}
#waterbed-config .carousel-control-next {
  left: auto;
  right: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#80000000",GradientType=1);
}
#waterbed-config .carousel-control-next > .fa, #waterbed-config .carousel-control-next > .far {
  font-size: 30px;
}
#waterbed-config .carousel-text {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 26px;
  bottom: 5px;
  font-size: 21px;
}
#waterbed-config > .contentbox {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
#waterbed-config > .contentbox > div {
  padding: 10px 15px;
}
#waterbed-config > .contentbox > div.choices {
  padding-bottom: 20px;
}
#waterbed-config .waterbed-config__monoorduo button,
#waterbed-config .waterbed-config__stabilisationchoice label {
  background-color: #fff;
  border-color: #584a45;
  color: #584a45;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #7a645a;
  border-radius: 4px;
  transition: all 0s;
}
#waterbed-config .waterbed-config__monoorduo button:focus,
#waterbed-config .waterbed-config__stabilisationchoice label:focus {
  box-shadow: none;
}
#waterbed-config .waterbed-config__monoorduo button:hover,
#waterbed-config .waterbed-config__stabilisationchoice label:hover {
  background-color: #e6e6e6;
  background-position: 0 -15px;
}
#waterbed-config.waterbed-config--mono button.waterbed-config__selectmono, #waterbed-config.waterbed-config--duo button.waterbed-config__selectduo,
#waterbed-config input[type=radio]:checked + label {
  background-color: #82cb25;
  border-color: #82cb25;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #77ba22;
  background-image: linear-gradient(to bottom, #82cb25, #66a01d);
  background-repeat: repeat-x;
  border-color: #66a01d #66a01d #3d5f11;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
#waterbed-config.waterbed-config--mono button.waterbed-config__selectmono:hover, #waterbed-config.waterbed-config--duo button.waterbed-config__selectduo:hover,
#waterbed-config input[type=radio]:checked + label:hover {
  background-color: #66a01d;
  background-position: 0 -15px;
}
#waterbed-config .heading {
  margin: 0;
  padding: 10px 15px;
  background-color: #fff;
  color: #584a45;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border-bottom: 1px solid #ddd;
}
#waterbed-config .waterbed-config__stabilisationchoice:after {
  display: block;
  content: "";
  clear: both;
}
#waterbed-config .waterbed-config__stabilisationchoice h3 {
  margin-bottom: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice ul {
  list-style-type: none;
  margin: 0 0 0 -15px;
  padding: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice ul > li {
  display: inline-block;
  padding-top: 10px;
  padding-left: 15px;
  vertical-align: top;
}
#waterbed-config .waterbed-config__stabilisationchoice label {
  vertical-align: top;
  padding: 0 40px;
  display: flex;
  min-height: 70px;
  align-self: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
}
#waterbed-config .waterbed-config__stabilisationchoice label > svg {
  position: absolute;
  width: 59px;
  height: 36px;
  top: -40px;
  left: 75px;
}
#waterbed-config .waterbed-config__stabilisationchoice label > span {
  display: inline-block;
  align-content: space-around;
  width: 100%;
  text-align: center;
}
#waterbed-config .waterbed-config__stabilisationchoice label > span.note {
  font-size: 13px;
}
#waterbed-config .waterbed-config__stabilisationchoice .mostchosen label {
  padding: 0 20px;
  border-color: #66a01d;
  border-width: 2px;
}
#waterbed-config .waterbed-config__stabilisationchoice input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice > .choices__left {
  float: left;
  width: 50%;
}
#waterbed-config .waterbed-config__stabilisationchoice > .choices__right {
  float: right;
  width: 50%;
}
#waterbed-config .description:after {
  clear: both;
  content: "";
  display: block;
}
#waterbed-config .description__left, #waterbed-config .description__right {
  width: 50%;
  max-width: 445px;
}
#waterbed-config .description__left {
  float: left;
}
#waterbed-config .description__right {
  float: right;
}
@media (max-width: 991px) {
  #waterbed-config .waterbed-config__stabilisationchoice label {
    padding: 0 30px;
  }
  #waterbed-config .waterbed-config__stabilisationchoice .mostchosen label {
    padding: 0 0;
  }
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__left,
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__right,
  #waterbed-config .description__left,
  #waterbed-config .description__right {
    float: none;
    width: 100%;
    max-width: 100%;
  }
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__right {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  #waterbed-config .waterbed-config__stabilisationchoice label > svg {
    display: none;
  }
}
#waterbed-config .waterbed-config__optionlist {
  margin-bottom: 20px;
}
#waterbed-config .waterbed-config__optionlist .table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 0 none;
  border-color: #ddd;
  padding-left: 0;
}
#waterbed-config .waterbed-config__optionlist tbody > tr:nth-child(2n) {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
#waterbed-config .waterbed-config__review {
  margin: 20px 0;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  position: relative;
}
#waterbed-config .waterbed-config__review .mm-recensie {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #01b0d5;
  color: #fff;
  font-size: 23px;
  text-align: center;
  padding-top: 10px;
}
#waterbed-config .waterbed-config__review .mm-recensie > span {
  display: block;
}
#waterbed-config .waterbed-config__review .mm-recensie > span + span {
  font-size: 12px;
}
#waterbed-config .waterbed-config__review .rating-stars-count {
  margin-top: 15px;
}
#waterbed-config .waterbed-config__review .rating-stars-count a {
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  color: #584a45;
}
#waterbed-config .waterbed-config__review .keurmerk {
  display: block;
  text-decoration: none;
  width: 120px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 20px;
}
#waterbed-config .waterbed-config__review .keurmerk > img {
  width: 100%;
  height: auto;
}
#waterbed-config .waterbed-config__totalprice {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  padding: 20px;
  font-size: 21px;
  margin: 20px 0;
}
#waterbed-config .waterbed-config__totalprice .webshop-product__price {
  font-size: inherit;
}
#waterbed-config .waterbed-config__totalprice .webshop-product__price:before {
  content: "€";
  display: inline-block;
  padding-right: 5px;
}
#waterbed-config .waterbed-config__totalprice button {
  margin-top: 10px;
  width: auto;
  display: inline-block;
}
#waterbed-config .waterbed-config__images {
  border: 1px solid #ddd;
  padding: 4px;
}
#waterbed-config .waterbed-config__images h1 {
  padding: 10px;
}
#waterbed-config button.waterbed-config__selectmono,
#waterbed-config button.waterbed-config__selectduo {
  padding: 20px 40px;
}
#waterbed-config .choices {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}
#waterbed-config .choices button + button {
  margin-left: 15px;
}

html.kt-configurator .hideon-kt-configurator,
html.waterbed-config .hideon-kt-configurator {
  display: none;
}

.product__image img {
  max-height: 100px;
  width: auto;
  cursor: pointer;
}

webshop-product[data-webshop-in-stock] .skw-instocktext {
  margin-top: 5px;
  font-size: 14px;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes {
  position: relative;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes .product__deliverytimes__text {
  display: none;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small {
  font-size: 14px;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small:after {
  font: normal normal normal 14px/1 "Font Awesome 5 Pro";
  content: "\f05a"; /* fa-info-circle */
  display: inline-block;
  cursor: pointer;
  color: #708788;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small:hover .product__deliverytimes__text, webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small:focus .product__deliverytimes__text {
  display: inline-block;
  position: absolute;
  bottom: 30px;
  background-color: #fff;
  left: 0;
  padding: 3px 10px;
  box-shadow: 3px 5px 7px 0 rgba(0, 0, 0, 0.12);
}
webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small:hover .product__deliverytimes__text:after, webshop-product[data-webshop-in-stock] .skw-instocktext:empty + .product__deliverytimes small:focus .product__deliverytimes__text:after {
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  display: inline-block;
  left: 46px;
  position: absolute;
  bottom: -7px;
}
webshop-product[data-webshop-in-stock] .skw-instocktext:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c"; /* fa-check */
  color: #75b325;
  line-height: 12px;
  font-size: 14px;
  margin-right: 5px;
}
webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable) .skw-instocktext:after {
  content: "Uit voorraad leverbaar";
}
webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="1"] .skw-instocktext:after {
  content: "Nog 1 op voorraad, OP = OP!";
}
webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="2"] .skw-instocktext:after {
  content: "Nog 2 op voorraad, OP = OP!";
}
webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="3"] .skw-instocktext:after {
  content: "Nog 3 op voorraad, OP = OP!";
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable .skw-instocktext:after {
  content: "Uit voorraad leverbaar";
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="1"] .skw-instocktext, webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="2"] .skw-instocktext, webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="3"] .skw-instocktext {
  display: block;
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="1"] .skw-instocktext:after {
  content: "Nog 1 op voorraad";
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="2"] .skw-instocktext:after {
  content: "Nog 2 op voorraad";
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="3"] .skw-instocktext:after {
  content: "Nog 3 op voorraad";
}
webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="0"] .skw-instocktext {
  display: none;
}

.readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}

.product_promoimage {
  text-align: right;
}

.product_promoimage > img {
  max-width: 200px;
}

.product__options #btn-kt-configurator,
.product__options .webshop-product__add,
.product__options #btn-waterbed-configurator {
  font-size: 16px;
}

@media (max-width: 999px) {
  .product__options .row {
    display: block;
  }
  .product__options .row > * {
    flex: none;
    max-width: 100%;
  }
}
.webshop-product__optiontitle {
  float: left;
  width: 100px;
}

.dd-container {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

.dd-selected {
  font-weight: normal;
  padding: 4px;
}

.dd-selected-text,
.webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
}

.product__tags {
  margin-top: 20px;
  overflow: hidden;
  height: 180px;
}
.product__tags.show {
  height: auto !important;
}
.product__tags .product__tag {
  background: transparent;
  padding: 10px 12px;
  display: inline-block;
  margin: 5px;
  color: #ddd;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}
.product__tags .product__tag:hover {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  border: 1px solid #ccc;
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .product__tags {
    height: 150px;
    overflow: hidden;
  }
  .product__tags .product__tag {
    font-size: 12px;
    padding: 5px 10px;
  }
}

#showmoretags {
  color: #6dbade;
  width: fit-content;
}
#showmoretags:after {
  content: "Toon meer";
}
#showmoretags:before {
  content: "\f107"; /* fa-angle-down */
  font-family: "Font Awesome 5 Pro";
}
#showmoretags.clicked:after {
  content: "Toon minder";
}
#showmoretags.clicked:before {
  content: "\f106"; /* fa-angle-up */
  font-family: "Font Awesome 5 Pro";
}

#product__tabs {
  margin-top: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0;
}
#product__tabs .card-header {
  background: #708788;
  border-radius: 0;
}
#product__tabs .card-header .nav-tabs .nav-link {
  border: 0px solid transparent;
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (max-width: 767px) {
  #product__tabs .card-header .nav-tabs .nav-link {
    border: 1px solid #fff;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
#product__tabs .card-header .nav-tabs .nav-link.active {
  color: #000000;
}
#product__tabs .card-body {
  padding: 0;
}
#product__tabs .tab-content {
  background: #fff;
}
#product__tabs .tab-pane {
  padding: calc(1rem + 10px);
}

.product__related {
  margin-top: 15px;
}

.product__spectable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: grey;
}

.product__spectablecell {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.product__spectablerow {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.product__spectablerow:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.product__price__arrow {
  margin: 35px 0 0 5px;
}

.skw-product__in3 {
  font-size: 12px;
}

.webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  background: url(../../web/img/vanbg.png) no-repeat 50%;
}

.webshop-product__price {
  font-size: 28px;
  font-weight: 600;
}

/*
  Hide <select> once the replacement .dd-container is live
*/
.dd-container + select {
  display: none;
}

.dd-selected {
  position: relative;
}

@media (min-width: 480px) and (max-width: 979px) {
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
/* waterbed configurator is initially hidden */
.waterbed-config--hidden {
  display: none;
}

.webshop-product__option--hidden,
.webshop-product__option--hiddendefault {
  display: none;
}