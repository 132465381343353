@media (max-width: 768px) {
  .customerservicebar {
    position: relative;
    margin-top: 15px;
    border-top: 7px solid #E9E9E9; /* #F9F9F9 html.webshop--iscategorypage body, html.webshop--istagpage body */
  }
  .customerservicebar__text {
    flex: 1 0 auto;
  }
  .categoryintro__imagewrapper {
    display: none;
  }
  .categoryintro__usps {
    margin-top: 0;
    display: table;
    width: -webkit-fill-available;
  }
  .categoryintro__usps .usp_item {
    display: table-row;
  }
  .categoryintro__usps .usp_item > * {
    display: table-cell;
    padding: 5px 0;
  }
  .categoryintro__usps .usp_item dd {
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .categoryintro--including-contentcta .categoryintro__imagewrapper {
    display: none;
  }
  .categoryintro--including-contentcta .categoryintro__usps {
    margin-top: 0;
    display: table;
    width: -webkit-fill-available;
  }
  .categoryintro--including-contentcta .categoryintro__usps .usp_item {
    display: table-row;
  }
  .categoryintro--including-contentcta .categoryintro__usps .usp_item > * {
    display: table-cell;
    padding: 5px 0;
  }
  .categoryintro--including-contentcta .categoryintro__usps .usp_item dd {
    text-align: center;
  }
}
/* Customer service bar floating bottom right */
@media (min-width: 768px) {
  .customerservicebar {
    position: absolute;
    right: -18px;
    bottom: 0;
    box-shadow: -1px -1px 11px rgba(0, 0, 0, 0.2745098039) !important;
  }
  .categoryintro:has(.customerservicebar) {
    margin-bottom: 75px;
  }
  .customerservicebar {
    transform: translateY(50%);
  }
}