html.plannerpage .main--margintop {
  margin: 0 auto;
  padding: 0 !important;
  margin-top: 0 !important;
}
html.plannerpage .pageimage {
  margin: 0 0 20px;
  text-align: center;
}
html.plannerpage .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
}
html.plannerpage body {
  background-color: #fff;
}
@media (max-width: 991px) {
  html.plannerpage .container {
    max-width: calc(100dvw - 40px) !important;
  }
}
@media (max-width: 575px) {
  html.plannerpage .container {
    max-width: none;
  }
}

html.plannerpage--dba main > .container:first-child {
  padding-top: 20px;
  max-width: 1140px;
}
@media (max-width: 575px) {
  html.plannerpage--dba .plannerform__wrapper {
    width: 100%;
  }
}

.plannerform__contents .widget-links {
  margin-left: 0;
  margin-right: 0;
}
.plannerform__contents .widget-links__itemswrapper {
  padding: 20px 0;
  border-bottom: 1px solid #D8D8D8;
  border-top: 1px solid #D8D8D8;
}
.plannerform__contents .widget-links h2, .plannerform__contents .widget-links ul {
  padding: 0;
}
.plannerform__contents .widget-links a {
  text-underline-position: under;
}
.plannerform__contents .widget-threecol {
  /*
      border-raidus en een padding+margin:
  Iets van een  margin 0.5% en een border-radius van 10px en het lijntje 1px solid #D8D8D8;
      */
}
.plannerform__contents .widget-threecol__columns__col {
  border: 1px solid #D8D8D8;
  border-radius: 10px;
  padding: clamp(20px, 25px, 2.5dvw);
}
.plannerform__contents .widget-threecol .row {
  margin: 0 0 0 -15px;
}
.plannerform__contents .widget-threecol .row > div {
  margin: 0 0 15px 15px;
  flex: none;
  width: calc(33.333% - 15px);
  border: 1px solid #D8D8D8;
  border-radius: 10px;
  padding: clamp(20px, 25px, 2.5dvw);
}
.plannerform__contents .widget-threecol .row > div > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .plannerform__contents .widget-threecol .row > div {
    width: calc(50% - 15px);
  }
}
@media (max-width: 550px) {
  .plannerform__contents .widget-threecol .row > div {
    width: calc(100% - 15px);
  }
}

.plannerform__wrapper {
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: clamp(30px, 50px, 5dvw);
}
.plannerform__wrapper .plannerform__intro {
  flex: 1 1 auto;
  margin-right: clamp(20px, 90px, 9dvw);
}
.plannerform__wrapper .plannerform__intro h2:first-child, .plannerform__wrapper .plannerform__intro h1 {
  margin-bottom: 0px;
}
.plannerform__wrapper .plannerform__intro p {
  color: #000;
}
.plannerform__wrapper .plannerform {
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: clamp(20px, 30px, 3dvw) clamp(15px, 20px, 2dvw);
  position: relative;
  flex: none;
  width: 450px;
  max-width: calc(50% - 40px);
  margin-top: clamp(-150px, -80px, -8dvw);
}
@media (max-width: 575px) {
  .plannerform__wrapper {
    flex-flow: column;
    width: calc(100dvw - 70px);
  }
  .plannerform__wrapper .plannerform {
    margin-bottom: 30px;
    max-width: none;
    width: 100%;
  }
  .plannerform__wrapper .plannerform__intro {
    margin-right: 0;
    flex: none;
    order: 2;
  }
}

.plannerform__heading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 700;
  font-size: clamp(22px, 28px, 2.8dvw);
}
.plannerform__heading svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

form#plannerform div[data-wh-form-group-for=day] {
  display: none !important;
}

form#plannerform.wh-form {
  width: 100%;
}
form#plannerform.wh-form .wh-form__prefillarea {
  position: absolute;
  top: 10px;
}
form#plannerform.wh-form .plannerform__pages {
  width: 100%;
  overflow: hidden;
  display: flex;
}
form#plannerform.wh-form .plannerpage__groupheader {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
  color: #000;
}
form#plannerform.wh-form .wh-form__optionlabel {
  color: #000;
}
form#plannerform.wh-form input[disabled] ~ .wh-form__optiondata {
  opacity: 0.3;
}
form#plannerform.wh-form .wh-form__page {
  flex: none;
  width: 100%;
  position: relative;
}
form#plannerform.wh-form .wh-form__page + .wh-form__page {
  margin-left: -100%;
}
form#plannerform.wh-form .wh-form__page.wh-form__page--hidden {
  display: block;
  z-index: -1;
}
form#plannerform.wh-form .wh-anchor {
  scroll-margin-top: 200px;
}
form#plannerform.wh-form .wh-form__fieldgroup {
  -webkit-flex-direction: column;
  flex-direction: column;
}
form#plannerform.wh-form .twocol-fieldgroups {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
form#plannerform.wh-form .twocol-fieldgroups .wh-form__fieldgroup {
  flex: none;
  width: calc(50% - 5px);
}
form#plannerform.wh-form .wh-form__fields {
  width: 100%;
  flex-basis: auto;
}
form#plannerform.wh-form .wh-form__fields input[type=email], form#plannerform.wh-form .wh-form__fields input[type=text], form#plannerform.wh-form .wh-form__fields textarea {
  max-width: 100%;
  resize: none;
  font-size: 16px;
  line-height: 20px;
}
form#plannerform.wh-form .wh-form__fields input[type=email], form#plannerform.wh-form .wh-form__fields input[type=text] {
  height: 40px;
}
form#plannerform.wh-form .wh-form__fields textarea {
  height: 72px;
}
form#plannerform.wh-form .wh-form__label {
  font-weight: normal;
  max-width: 100%;
  color: #000;
}
form#plannerform.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  padding: 12px 25px 10px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  margin-bottom: 10px;
}
form#plannerform.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline .wh-form__optionlabel {
  margin-bottom: 0;
}
form#plannerform.wh-form .wh-form__buttongroup {
  margin-top: 10px;
  display: none;
}
form#plannerform.wh-form .wh-form__buttongroup .wh-form__button:not(.wh-form__button--previous) {
  float: right;
}
form#plannerform.wh-form.wh-form--allowprevious .wh-form__buttongroup {
  display: block;
}
form#plannerform.wh-form .wh-form__fieldgroup--required > .wh-form__label:after {
  content: "";
}
form#plannerform.wh-form .wh-form__buttonlabel {
  display: flex;
  align-items: center;
}
form#plannerform.wh-form .wh-form__button--submit .wh-form__buttonlabel::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f783";
  margin-right: 10px;
}

/*
.plannerpage__explaindagger
{
  text-align:right;
  font-size:80%;
  font-style:italic;
}


.plannerpage--yellow
{
  font-weight:bold;
  color: #b89428 !important;
}

.plannerpage__arrivaltime
{
  display:block;
  text-align:right;
  font-size:80%;
  font-style:italic;

  @media (max-width:380px)
  {
    display:none;
  }
}

*/