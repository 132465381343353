/* Large desktop */
@media (min-width: 1300px) {
  #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
/* Desktop */
@media (min-width: 979px) and (max-width: 1300px) {
  #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  #image-preview img {
    max-width: 400px;
  }
}
#image-preview {
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}