.plannerpagecalendar {
  display: block;
}
.plannerpagecalendar .calendar {
  background-color: #fff;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.plannerpagecalendar .calendar button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}
.plannerpagecalendar .calendar .calendar__header {
  padding: 10px 10px clamp(10px, 30px, 3dvw);
  color: #000;
  white-space: nowrap;
  text-align: center;
}
.plannerpagecalendar .calendar .calendar__header__inner {
  display: flex;
  justify-content: space-between;
}

.calendar-top .datepicker-placeholder .calendar {
  top: auto;
  bottom: -3px;
}

.wh-monthtable {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  cursor: default;
}
.wh-monthtable td, .wh-monthtable th {
  font-size: clamp(14px, 18px, 1.6dvw);
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  height: 32px;
  color: #000;
}
.wh-monthtable .wh-monthtable__weekday {
  font-weight: 700;
}
.wh-monthtable .wh-monthtable__day > span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  color: inherit;
  width: clamp(20px, 50px, 4dvw);
  height: clamp(20px, 50px, 4dvw);
  border-radius: 50%;
  transition: color 0.3s, background-color 0.3s;
}
.wh-monthtable .wh-monthtable__day:not(.wh-monthtable__day--disabled) {
  cursor: pointer;
}
.wh-monthtable .wh-monthtable__day:not(.wh-monthtable__day--disabled):hover > span,
.wh-monthtable .wh-monthtable__day.wh-monthtable__day--selected > span {
  color: #fff;
  background-color: #708788;
  text-shadow: 0 0 1px #fff;
}
.wh-monthtable .wh-monthtable__day--disabled {
  opacity: 0.25;
}