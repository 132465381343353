html.webshop--iscategorypage body,
html.webshop--istagpage body {
  background-color: #f9f9f9;
}
html.webshop--iscategorypage body main,
html.webshop--istagpage body main {
  padding: 1rem;
  background-color: #fff;
}
html.webshop--iscategorypage .page-container-twocolumn .product_listing,
html.webshop--istagpage .page-container-twocolumn .product_listing {
  padding-bottom: 15px;
  padding-top: 30px;
  margin-bottom: 1.5rem;
  margin: 0 -16px 0 -16px;
}

html.sidebarjs--open {
  overflow-y: hidden;
}

.sidereview {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #eee;
}
.sidereview .ratingcomment {
  background-color: #f9f9f9;
}
.sidereview .ratingcomment:after {
  border-top: 10px solid #f9f9f9;
}

/* anchor/link for mobile layout */
.productsgrid__bottomanchorlink {
  display: inline-block;
  margin-bottom: 10px;
}

#productsgrid_anchor {
  position: absolute;
  top: -100px;
  left: 0;
}

#category_listing {
  background-color: #fff;
  margin: 0 -16px 10px;
  padding: 25px 25px 0 25px;
}

.webshop--ishomepage .webshop-products--ordering-container {
  display: none;
}