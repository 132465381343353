/*
.widget-links
{
  background:   var(--contentpanel-background);
  padding:      var(--contentpanel-pad-v) 0;
  box-shadow:   var(--contentpanel-box-shadow);
}
*/

.widget-links h2
{
/*  padding:      0 var(--contentpanel-pad-h);*/
  padding: 0 var(--contentarea-pad-h);
}

/* wrapper for positioning the previous and next button over the items */
.widget-links__itemswrapper
{
  position: relative;
}

.widget-links__items
{
  /* reserve space for the horizontal scrollbar on touch */
  padding-bottom: 7px;
}

.widget-links.canscrollleft .widget-links__items
{
  --mask-left: 125px;
}

.widget-links.canscrollright .widget-links__items
{
  --mask-right: 125px;
}

/* ADDME: transition to delay switching pointer-events to prevent user accidentally clicking a link at the place where the button was */

  .widget-links__previousbutton
, .widget-links__nextbutton
{
  position: absolute;
  top:      3px;
  width:    calc(var(--component-tag-height) - 6px);
  height:   calc(var(--component-tag-height) - 6px);
  z-index:  1;

  pointer-events: none;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;

  padding: 0;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  border-radius: 50%;
}

.widget-links__previousbutton
{
/*  left: var(--contentpanel-pad-h);*/
  left: var(--contentarea-pad-h);
}

.widget-links__previousbutton::before
{
  content: var(--fa-chevron-left);
/*  font: var(--fontawesome-regular);*/
  font-family: 'Font Awesome 5 Pro';
  font-size: 18px;
  color: #000000;
  margin-top: 2px;
  margin-left: -2px;
}

.widget-links__nextbutton
{
/*  right: var(--contentpanel-pad-h);*/
  right: var(--contentarea-pad-h);
}

.widget-links__nextbutton::before
{
  z-index: 1;

  content: var(--fa-chevron-right);
/*  font: var(--fontawesome-regular);*/
  font-family: 'Font Awesome 5 Pro';
  font-size: 18px;
  color: #000000;

  margin-left: 3px;
}

  .widget-links.canscrollleft .widget-links__previousbutton
, .widget-links.canscrollright .widget-links__nextbutton
{
  pointer-events: auto;
  opacity: 1;
}


@media (max-width: 550px)
{
    .widget-links__previousbutton
  , .widget-links__nextbutton
  {
    display: none;
  }
/*
  .widget-links.canscrollleft .widget-links__items
  {
    --mask-left: 25px;
  }

  .widget-links.canscrollright .widget-links__items
  {
    --mask-right: 25px;
  }
*/
}


@media (min-width: 551px)
{
  .widget-links__items
  {
    --mask-left:  0px;
    --mask-right: 0px;

    -webkit-mask-image: linear-gradient(to right
            , rgba(0,0,0,0.1) 0
            , rgba(0,0,0,0.1) calc(var(--mask-left) / 8)
            , black           var(--mask-left)
            , black           calc(100% - var(--mask-right))
            , rgba(0,0,0,0.1) calc(100% - var(--mask-right) / 8)
            , rgba(0,0,0,0.1) 100%);

    mask-image: linear-gradient(to right
            , rgba(0,0,0,0.1) 0
            , rgba(0,0,0,0.1) calc(var(--mask-left) / 8)
            , black           var(--mask-left)
            , black           calc(100% - var(--mask-right))
            , rgba(0,0,0,0.1) calc(100% - var(--mask-right) / 8)
            , rgba(0,0,0,0.1) 100%);
  }
}


.widget-links ul
{
  list-style: none;

  display: flex;
  max-width: 100%;

  overflow: auto;

  /*
  https://stackoverflow.com/questions/3296644/hiding-the-scroll-bar-on-an-html-page

  overflow: -moz-scrollbars-none; // Firefox <63 (before aug 2018) - might also block any scrolling
  &::-webkit-scrollbar { display: none; } // Chrome, Safari, newer versions of Opera
  */
  scrollbar-width: none;          /* Firefox 64+ */
  &::-webkit-scrollbar { height: 0 !important } /* Chrome, Safari, newer versions of Opera */

  -webkit-overflow-scrolling: touch;

/*  column-gap: var(--component-tagslist-spacing);*/

  margin: 0;
/*  padding: 0 var(--contentpanel-pad-h);*/
  padding: 0 var(--contentarea-pad-h);
}

.widget-links li
{
  display: inline-block;
  vertical-align: middle;
}

.widget-links li + li
{
  margin-left: var(--component-tagslist-spacing);
}

.widget-links li a
{
  display: flex;
  padding: 10px;
  border-radius: 10px;

  align-items: center;
  background: var(--component-tag-background);
  border: var(--component-tag-border);
  height: var(--component-tag-height);
  color: inherit;
  font: var(--component-tag-font);
  color: #000000;

  white-space: nowrap;

  text-decoration: none;
}

.widget-links li a:hover
{
  text-decoration: underline;
}

.widget-links li a img
{
  width: var(--component-tag-image-height);
  height: var(--component-tag-image-height);
  margin-right: 10px;
}
