html.webshop--ishomepage body,
html.webshop--ishomepage .bg-light {
  background-color: #fff !important;
}
html.webshop--ishomepage .main--margintop {
  margin: 0 auto;
  padding: 0 !important;
  margin-top: 0 !important;
}
html.webshop--ishomepage .productitem--category .productitem__footer {
  display: none;
}
html.webshop--ishomepage .product_listing,
html.webshop--ishomepage main .twocol__banner,
html.webshop--ishomepage main .twocol {
  padding: 35px 15px 35px 15px;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 576px) {
  html.webshop--ishomepage .product_listing,
  html.webshop--ishomepage main .twocol__banner,
  html.webshop--ishomepage main .twocol {
    padding: 15px;
  }
}
html.webshop--ishomepage .webshop-products-mini .product_listing {
  padding: 5px 0;
}
html.webshop--ishomepage #category_listing {
  padding: 50px 30px;
}
@media (max-width: 576px) {
  html.webshop--ishomepage body.scroll {
    margin-top: 28px;
  }
  html.webshop--ishomepage body.scroll header .topbar {
    margin-top: -43px;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage .product_listing {
    max-width: 100%;
    margin: 0 auto;
  }
  html.webshop--ishomepage .product_listing .productsgrid {
    max-width: 1170px;
    margin: 0 auto;
  }
  html.webshop--ishomepage main .twocol__banner,
  html.webshop--ishomepage main .twocol {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage .product_listing {
    max-width: 100%;
    margin: 0 auto;
  }
  html.webshop--ishomepage .product_listing .productsgrid {
    max-width: 1340px;
    margin: 0 auto;
  }
  html.webshop--ishomepage main .twocol__banner,
  html.webshop--ishomepage main .twocol {
    max-width: 1340px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

html.webshop--ishomepage main > * {
  max-width: 100%;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}