@charset "UTF-8";
html {
  --checkout-item-padding: 5px 0;
}

.webshop--ischeckoutpage body {
  background-color: #f9f9f9 !important;
}
.webshop--ischeckoutpage .bg-light {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.webshop-checkout__form .skw-checkout__userdata {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.webshop-checkout__form .wh-form__fieldgroup {
  flex: 1 0 100%;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone2],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"] {
  flex: 0 0 310px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] {
  flex: 0 0 110px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"] .wh-form__error,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] .wh-form__error {
  width: 1px;
  min-width: 0;
  white-space: nowrap;
}
.webshop-checkout__form #orderform-billing_address\.zip, .webshop-checkout__form #orderform-shipping_address\.zip {
  width: 100px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] {
  flex: 0 0 calc(100% - 310px);
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] input,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] input {
  max-width: 100px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] .wh-form__label {
  min-width: 0;
}
.webshop-checkout__form #orderform-customercomment {
  max-width: none;
  height: 70px;
}
@media (max-width: 767px) {
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone2],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] {
    flex: 0 0 calc(50% - 5px);
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=billing_orgname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=billing_vatnr] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.country"] .wh-form__pulldown,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=shipping_receivername] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=shipping_orgname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.country"] .wh-form__pulldown,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email] input {
    max-width: 100%;
  }
  .webshop-checkout__form .skw-checkout__userdata {
    justify-content: space-between;
  }
}
@media (max-width: 500px) {
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"] {
    flex: 0 0 calc(100% - 110px);
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"] input {
    max-width: 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] {
    flex: 0 0 100px;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] input {
    max-width: 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone2] {
    flex: 0 0 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone2] input {
    max-width: 100%;
  }
}

.webshop-checkout__userdata {
  margin-bottom: 20px;
}

.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]):not([data-wh-form-group-for=wrd_gender]), .webshop-checkout__form .wh-form__fieldgroup--checkbox {
  margin-bottom: 10px;
}
.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]):not([data-wh-form-group-for=wrd_gender]) > label, .webshop-checkout__form .wh-form__fieldgroup--checkbox > label {
  display: none !important;
}
.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]):not([data-wh-form-group-for=wrd_gender]) .wh-form__radio, .webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]):not([data-wh-form-group-for=wrd_gender]) .wh-form__checkbox, .webshop-checkout__form .wh-form__fieldgroup--checkbox .wh-form__radio, .webshop-checkout__form .wh-form__fieldgroup--checkbox .wh-form__checkbox {
  position: relative;
  top: 1px;
}

.skw-orderstatusblock {
  display: flex;
  padding-bottom: 20px;
}
.skw-orderstatusblock__image {
  font-size: 10px;
  flex: 0 0 50px;
}
.skw-orderstatusblock__image .fa, .skw-orderstatusblock__image .far {
  font-size: 26px;
}
.skw-orderstatusblock__image .fa-check, .skw-orderstatusblock__image .far-check {
  color: #75b325;
}
.skw-orderstatusblock #popup-spraypay {
  top: 0;
}

.webshop-listcart__productstable {
  width: 100%;
}

.webshop-listcart__products {
  margin-bottom: 20px;
}
.webshop-listcart__products tr.webshop-listcart__productrow > td,
.webshop-listcart__products tr.webshop-listcart__productdiscountrow > td {
  vertical-align: top;
  padding: 5px;
}
.webshop-listcart__products tr.webshop-listcart__productrow > td.webshop-listcart__deletecell,
.webshop-listcart__products tr.webshop-listcart__productdiscountrow > td.webshop-listcart__deletecell {
  padding-top: 10px;
}
.webshop-listcart__products tr.webshop-listcart__productrow > td.webshop-listcart__deletecell .fa, .webshop-listcart__products tr.webshop-listcart__productrow > td.webshop-listcart__deletecell .far,
.webshop-listcart__products tr.webshop-listcart__productdiscountrow > td.webshop-listcart__deletecell .fa,
.webshop-listcart__products tr.webshop-listcart__productdiscountrow > td.webshop-listcart__deletecell .far {
  cursor: pointer;
}
.webshop-listcart__products tr.webshop-listcart__productrow {
  border-top: 1px solid #ddd;
}
.webshop-listcart__products tr.webshop-listcart__producttotalrow {
  background: #f9f9f9;
}
.webshop-listcart__products tr.webshop-listcart__producttotalrow:nth-of-type(even) {
  border-top: 1px solid #ddd;
}
.webshop-listcart__products .webshop-listcart__controls .webshop-listcart__productamountcell {
  padding-top: 5px;
  width: 90px;
  border-bottom: 1px solid #ddd;
}
.webshop-listcart__products .webshop-listcart__optionlabel {
  font-style: italic;
}
.webshop-listcart__products .webshop-listcart__optionlabel:after {
  content: ": ";
}
.webshop-listcart__products .webshop-listcart__productdelete {
  color: #337ab7;
  cursor: pointer;
}
.webshop-listcart__products .webshop-listcart__productdelete:hover {
  color: inherit;
}
.webshop-listcart__products .webshop-listcart__linetotalcell, .webshop-listcart__products .webshop-listcart__totalcell {
  text-align: right;
  white-space: nowrap;
}
.webshop-listcart__products .listcart__producttotaldiscountcell {
  color: #75b325;
  font-weight: 600;
}
.webshop-listcart__products .webshop-listcart__productdiscountimagecell::before,
.webshop-listcart__products .webshop-listcart__orderdiscountimagecell::before,
.webshop-listcart__products .webshop-listcart__productdiscount .webshop-listcart__imagecell::before,
.webshop-listcart__products .webshop-listcart__orderdiscount .webshop-listcart__imagecell::before {
  font: normal normal normal 24px "Font Awesome 5 Pro";
  content: "\f02b"; /* fa-tag */
  padding-left: 15px;
}
.webshop-listcart__products .webshop-listcart__productdiscountrow, .webshop-listcart__products .webshop-listcart__orderdiscountrow,
.webshop-listcart__products .webshop-listcart__discounttotalrow {
  color: #75b325;
  font-weight: 500;
}

.webshop-checkout__paymentmethods .wh-form__richtext *:last-child {
  margin-bottom: 0;
}

.webshop-checkout__paymentmethods,
.webshop-checkout__shippingmethods {
  margin-bottom: 20px;
}
.webshop-checkout__paymentmethods > .wh-form__label,
.webshop-checkout__shippingmethods > .wh-form__label {
  display: none;
}
.webshop-checkout__paymentmethods input[type=radio],
.webshop-checkout__shippingmethods input[type=radio] {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
  top: auto !important;
}
.webshop-checkout__paymentmethods .wh-form__fieldline,
.webshop-checkout__shippingmethods .wh-form__fieldline {
  border-top: 1px solid #ddd;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  vertical-align: bottom;
  /* Checkmark bij gekozen betaaloptie */
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__pulldown {
  width: 155px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline > *,
.webshop-checkout__shippingmethods .wh-form__fieldline > * {
  padding: var(--checkout-item-padding);
  min-height: 40px;
  display: flex;
}
.webshop-checkout__paymentmethods .wh-form__fieldline > *.webshop-checkout__costs,
.webshop-checkout__shippingmethods .wh-form__fieldline > *.webshop-checkout__costs {
  display: block;
  position: absolute;
  padding-top: 5px;
  top: 0;
  right: 5px;
  pointer-events: none;
}
.webshop-checkout__paymentmethods .wh-form__fieldline label,
.webshop-checkout__shippingmethods .wh-form__fieldline label {
  margin-bottom: 0;
  cursor: pointer;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label {
  flex: 0 0 30px;
  position: relative;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label:before {
  display: inline-block;
  content: "";
  width: 30px;
  position: absolute;
  top: 11px;
  left: 5px;
  font-family: "Font Awesome 5 Pro";
  font-size: 26px;
  color: #75b325;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input:checked + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input:checked + label:before {
  content: "\f00c"; /* fa-check */
  background: none;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label:before {
  background: url(../../web/img/radiobutton.png) no-repeat 1px -2px;
  width: 30px;
  background-size: 24px;
  height: 30px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input:checked ~ *, .webshop-checkout__paymentmethods .wh-form__fieldline:hover > *,
.webshop-checkout__shippingmethods .wh-form__fieldline input:checked ~ *,
.webshop-checkout__shippingmethods .wh-form__fieldline:hover > * {
  background-color: #ecf9ff;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label {
  width: 100%;
  padding: 3px 5px 3px 50px;
  display: block;
  position: relative;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 450px) {
  .webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label,
  .webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label {
    max-width: 210px;
  }
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__subfield {
  margin-left: 60px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__richtext,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__richtext {
  font-style: italic;
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label:before {
  background-image: url(../../web/img/shipping/bezorging_montage.png);
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label[for*=PACKET]:before {
  background-image: url(../../web/img/shipping/pakket_bezorging.png);
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label[for*=MAILBOX]:before {
  background-image: url(../../web/img/shipping/post_bezorging.png);
}

.webshop-checkout__paymentmethods .wh-form__optiondata > label:before {
  background-image: url(../../web/img/payment/payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_IDEAL]:before {
  background-image: url(../../web/img/payment/ideal-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_BANCONTACT]:before {
  background-image: url(../../web/img/payment/bancontact.svg);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_GIFTCARD]:before {
  background-image: url(../../web/img/payment/vvv.svg);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_PAYNL__1813]:before {
  background-image: url(../../web/img/payment/in3-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_PREWIRE]:before {
  background-image: url(../../web/img/payment/overboeking-payment.png);
  top: 3px;
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_REMBOURS]:before,
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_CASHATPICKUP]:before {
  background-image: url(../../web/img/payment/cash-payment.png);
}

/*
  .webshop-listcart__productlinetotal:before
, .webshop-listcart__productdiscountlinetotal:before
, .webshop-listcart__orderdiscountlinetotal:before
, .webshop-checkout__ordertotal:before
, .webshop-listcart__producttotal:before
, .webshop-listcart__discounttotal:before
, .webshop-listcart__baskettotal:before
, .webshop-checkout__cost:before
{
  content: "€ ";
}
*/
.webshop-checkout__ordertotals,
.webshop-listcart__productlinetotalcell,
.webshop-listcart__productdiscountlinetotalcell,
.webshop-listcart__orderdiscountlinetotalcell {
  text-align: right;
  white-space: nowrap;
}

.webshop-listcart__separatorcell::before {
  content: " ";
}

input.webshop-checkout__addcouponcode {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  font: inherit;
  font-size: 12px;
  padding: 5px 10px;
  max-width: 300px;
  -webkit-appearance: none;
}

.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
}

.webshop-account__meta {
  margin-bottom: 20px;
}
.webshop-account__meta .webshop-account__orderid {
  font-weight: bold;
}

.webshop-account__invoices ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}
.webshop-account__invoices ul li {
  display: block;
  padding-bottom: 3px;
  padding-left: 22px;
  position: relative;
}
.webshop-account__invoices ul li:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  font: normal normal normal 24px "Font Awesome 5 Pro";
  content: "\f1c1"; /* fa-file-pdf */
  font-size: 16px;
}

.webshop-account__address {
  display: flex;
  margin-bottom: 20px;
}
.webshop-account__address > div {
  flex: 1 1 50%;
}
.webshop-account__address .webshop-account__fullorderaddress {
  padding-left: 20px;
}
@media (max-width: 450px) {
  .webshop-account__address {
    display: block;
  }
  .webshop-account__address .webshop-account__fullorderaddress {
    padding-top: 10px;
    padding-left: 0;
  }
}

.webshop-account__ordercontents {
  max-width: 100%;
  overflow-x: auto;
}
.webshop-account__ordercontents table {
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
}
.webshop-account__ordercontents th, .webshop-account__ordercontents td {
  vertical-align: top;
  text-align: left;
  padding-right: 10px;
}
.webshop-account__ordercontents th.right, .webshop-account__ordercontents td.right {
  text-align: right;
  padding-left: 10px;
  padding-right: 0;
}
.webshop-account__ordercontents .webshop-account__productlineprice, .webshop-account__ordercontents .webshop-account__productlinetotal,
.webshop-account__ordercontents tfoot .webshop-account__productlinetitle,
.webshop-account__ordercontents .webshop-account__titletotal,
.webshop-account__ordercontents .webshop-account__total,
.webshop-account__ordercontents .webshop-account__labelprice,
.webshop-account__ordercontents .webshop-account__labeltotal {
  text-align: right;
  padding-left: 10px;
  padding-right: 0;
}
.webshop-account__ordercontents .price {
  white-space: nowrap;
}
.webshop-account__ordercontents .price:before {
  content: "€";
  display: inline-block;
  padding-right: 2px;
}
.webshop-account__ordercontents .webshop-account__productlineimage > img {
  width: 60px;
  height: auto;
}
.webshop-account__ordercontents .webshop-account__productlinetitle {
  width: 100%;
  min-width: 100px;
}
.webshop-account__ordercontents thead th {
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
  white-space: nowrap;
}
.webshop-account__ordercontents tfoot td {
  border-top: 1px solid #ddd;
}
.webshop-account__ordercontents tfoot tr:last-child td {
  font-weight: bold;
}
.webshop-account__ordercontents td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.webshop-account__ordercontents td.webshop-account__productlinetotal, .webshop-account__ordercontents td.webshop-account__total {
  padding-left: 25px;
}
@media (max-width: 550px) {
  .webshop-account__ordercontents thead th:nth-child(1),
  .webshop-account__ordercontents tbody td:nth-child(1) {
    padding-right: 5px;
  }
  .webshop-account__ordercontents thead th:nth-child(2),
  .webshop-account__ordercontents tbody td:nth-child(2) {
    padding-right: 0;
    width: 0;
  }
  .webshop-account__ordercontents .webshop-account__productlineimage > img {
    display: none;
  }
  .webshop-account__ordercontents td.webshop-account__productlinetotal,
  .webshop-account__ordercontents td.webshop-account__total {
    padding-left: 10px;
  }
}

.checkout-codelist {
  padding: 0px;
  margin: 0 0 20px 0;
  list-style-type: none;
}

.codelist__line {
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: bottom;
  align-items: center;
}

.checkout-codelist .codelist__line:nth-last-child(1) {
  border-bottom: 1px solid #ddd;
}

.codelist__title {
  position: relative;
  display: inline-block;
  color: #75b325;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 45px;
  min-height: 30px;
}

.codelist__title::before {
  position: absolute;
  left: 5px;
  top: 5px;
  content: "\f02b"; /* fa-tag */
  font: normal normal normal 24px "Font Awesome 5 Pro";
  color: #75b325;
}

.codelist__removecode {
  display: inline-block;
  top: 5px;
}

.codelist__removecode::before {
  content: "\f014"; /* fa-thrash-o */
  font: normal normal normal 18px "Font Awesome 5 Pro";
  padding-top: 4px;
  padding-right: 10px;
  color: #337ab7;
  cursor: pointer;
}

.webshop-listcart__ordertotalrow .webshop-listcart__totallabel {
  display: none;
}
.webshop-listcart__ordertotalrow .webshop-listcart__total:before {
  content: "Totaal incl. BTW";
  font-weight: bold;
}

.webshop-listcart__totallabel:after {
  content: ": ";
}

.webshop-listcart__row--inactive {
  text-decoration: line-through;
}

.webshop-listcart__total {
  display: inline-block;
  min-width: 45px;
}

html.webshop--ischeckoutpage #sidebar .widget-login {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
}

@media (min-width: 801px) {
  .skw-listcart__mobiletitlerow {
    display: none;
  }
}
@media (max-width: 800px) {
  html.webshop--ischeckoutpage #sidebar,
  html.webshop--ischeckoutdonepage #sidebar {
    display: none;
  }
  tr.webshop-listcart__productrow > td,
  tr.webshop-listcart__productdiscountrow > td {
    padding: 0;
  }
  .skw-listcart__mobiletitlerow {
    display: block;
  }
  .skw-listcart__mobiletitlerow:not(:first-child) {
    margin-top: 25px;
  }
  .webshop-listcart__productrow {
    padding-top: 10px;
  }
  .webshop-listcart__row {
    display: grid;
    grid-template-columns: 87px 15px 1fr min-content minmax(60px, max-content);
    grid-template-rows: min-content min-content;
  }
  .webshop-listcart__imagecell {
    grid-row: 1/span 2;
    grid-column: 1;
    padding: 0 !important;
  }
  .webshop-listcart__titlecell {
    grid-row: 1;
    grid-column: 3/span 3;
    padding: 0 !important;
  }
  .webshop-listcart__productamountcell {
    grid-row: 2;
    grid-column: 3;
    align-self: baseline;
    padding: 5px 5px 5px 0px !important;
  }
  .webshop-listcart__selectamount {
    width: max-content !important;
    max-width: 100%;
    overflow: hidden;
  }
  .webshop-listcart__deletecell {
    grid-row: 2;
    grid-column: 4;
    align-self: baseline;
    padding: 5px 5px 5px 5px !important;
  }
  .webshop-listcart__linetotalcell {
    grid-row: 2;
    grid-column: 5;
    align-self: baseline;
    text-align: right;
    padding: 5px 0 5px 5px !important;
  }
  .webshop-listcart__productdiscountrow .webshop-listcart__linetotalcell {
    padding: 0 !important;
  }
  .webshop-listcart__orderdiscountrow {
    margin-top: 30px;
  }
  .webshop-listcart__orderdiscountrow .webshop-listcart__titlecell {
    grid-row: 1;
    grid-column: 1/span 3;
    text-align: right;
    align-self: baseline;
  }
  .webshop-listcart__orderdiscountrow .webshop-listcart__deletecell {
    grid-row: 1;
    grid-column: 4;
  }
  .webshop-listcart__orderdiscountrow .webshop-listcart__linetotalcell {
    grid-row: 1;
    grid-column: 5;
    padding: 0 !important;
  }
  /*
  Old listcart rendering override for mobile / tablet

    //replace tablerendering of controls with flex for more control
      .webshop-listcart__productrow
    , .webshop-listcart__productdiscountrow
    {
      display:block;
      position:relative;

      //reserve space for image!
      padding-left: 100px;
      min-height:100px;

      .webshop-listcart__imagecell
      {
        display:block;
        position:absolute;
        left:0;
        top:0;
      }
      .webshop-listcart__titlecell
      {
        display:block;
      }

      .webshop-listcart__productamountcell
      {
        display:block;
        float:left;
        width:34%;
      }
      .webshop-listcart__deletecell
      {
        display:block;
        float:left;
        width:65%;
        text-align:center;
      }
      .webshop-listcart__productamountcell + .webshop-listcart__deletecell
      {
        width:33%;
      }
      .webshop-listcart__linetotalcell
      {
        display:block;
        float:left;
        width:33%;
        text-align:right;
      }
  */
  .webshop-listcart__productrow .webshop-listcart__title {
    display: none;
  }
}
.skw-shippingmethod__deliverytimes {
  display: block; /* zorg dat deze op een eigen regel komt */
  margin-top: 3px;
  font-style: italic;
  font-size: 90%;
}