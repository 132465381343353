.categoryintro__content
{
  position: relative;
}

.customerservicebar
{
  overflow: hidden; /* cut off the background circle arc */

  display: flex;
/* box-shadow: var(--panel-box-shadow);*/

  /*
  border-top: 6px solid rgba(255,255,255,0.3);
  border-left: 6px solid rgba(255,255,255,0.3);
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  */

  /* zorg dat het over onderliggende pagina content heen komt.
     NOTE: .productitem:hover heeft z-index: 1; dus we moeten daarboven zitten.
  */
  z-index: 5;
}

.customerservicebar:not(.officehours) .customerservicebar__phone
{
  display: none;
}

.customerservicebar__text
{
  background: #FFFFFF;
  padding: 12px 25px 8px 25px;
  font-size: 15px;
  line-height: 18px;
}

.customerservicebar__heading
{
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 3px;
}

.customerservicebar__options
{
  margin-top: 10px;
  display: flex;
  column-gap: 25px;

  white-space: nowrap;
}

.customerservicebar__options > a
{
  color: inherit;

  display: flex;
  align-items: center;

  text-decoration: none;
}

.customerservicebar__options > a:hover .text
{
  text-decoration: underline;
  text-underline-offset: 3px;
  /*
  // text-decoration-thickness: 4px;
  // text-decoration-color: var(--button-underline-color);
  */
}

.customerservicebar__options .icon
{
  margin-right: 7px;
}

.customerservicebar__person
{
  display:    grid;
  width:      102px;
  min-height: 102px;
  background: #6f8788;
}

.customerservicebar::before
{
  content: "";
  background: #677c7d;

  border-radius: 50%;
  width: 140px;
  height: 190px;

  position: absolute;
  right: -65px;
  bottom: -91px;
}

.customerservicebar__person__circle
{
  grid-column: 1;
  grid-row: 1;

  margin-top: 7px;
  display: grid;

  align-self: center;
  justify-self: center;

  position: relative;

  --customerservicebar-person-border-width: 4px;
}

.customerservicebar__person__name + .customerservicebar__person__circle
{
  margin-top: 4px;
}

.customerservicebar__person__circle::before
{
  content: "";

  grid-column: 1;
  grid-row: 1;

/*  justify-self: center;*/

/*   clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%, 0 50%);*/

  width: 68px;
  height: 68px;
  background: #FFFFFF;
  border: var(--customerservicebar-person-border-width) solid #b1ad98;
  border-radius: 50%;
}

.customerservicebar__person__name
{
  grid-column: 1;
  grid-row: 1;

  z-index: 5;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);

  align-self: end;
  justify-self: end;

  color: #FFFFFF;
  transform: rotateZ(-15deg);

  margin-right: 7px;
  margin-bottom: 7px;
}

.customerservicebar__person img
{
  grid-column: 1;
  grid-row: 1;

  z-index: 2;

/*background-color: #FF0000;*/
/*
 clip-path: path("M 0 0 \
                  M 0 60 \
                  C 0 90, 30 90, 30 90 \
                  C 30 90, 60 90, 60 60 \
                  L 90 0 \
                  L 0 0 \
                  Z \
                  ");
*/

/*
 clip-path: path("M 4 0 \
                  M 4 60 \
                  C 4 86, 34 86, 34 86 \
                  C 34 86, 64 86, 64 60 \
                  L 86 0 \
                  L 4 0 \
                  Z \
                  ");
*/

  border-radius: 0 0 38px 38px;

  border: 4px solid transparent;
  width: 100%;

  object-fit: cover;
/*  width: 68px;*/
/*  height: 98px;*/

  position: absolute;
  bottom: 0;
  right: 0;
}
