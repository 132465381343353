.waterbed-config--singlechoice .waterbed-config__typechoice {
  display: none;
}

.waterbed-config__typemono,
.waterbed-config__typeduo,
.waterbed-config__order {
  display: none;
}

.waterbed-config--singlechoice .waterbed-config__monoorduo,
.waterbed-config--mono .waterbed-config__typeduo,
.waterbed-config--duo .waterbed-config__typemono {
  display: none !important;
}

.waterbed-config--mono .waterbed-config__typemono {
  display: block;
}

.waterbed-config--duo .waterbed-config__typeduo {
  display: block;
}

.waterbed-config--complete .waterbed-config__order {
  display: block;
}