#sidebar aside
{
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
#sidebar aside h4 a:hover
{
  color:#000;
}
.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}


/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding: 20px 20px 5px 20px;
  border: 1px solid #e8dbbe;
  background-color: #faf6ed;
  margin: 20px 0;
}
#filterSideNav .widget-productfilter
{
  border: 0px solid #e8dbbe;
  background-color: transparent;
  padding:0 0 5px 0;
}
#filterSideNav .widget-productfilter .aside__title
{
/*  color:#fff;*/
}
#filterSideNav .productfilter__activelabel {
  background-color: #666;
  color:#fff;
}
.productfilter__activelabel
{
  background-color: #fff;
  padding: 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 3px;
}

/* The "x" icon on the tag for each selected filter */
.productfilter__activeremove:before
{
  content: "x";
  float: right;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
}

.productfilter--optionlist
{
  max-height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
}
.productfilter
{
  padding-bottom: 15px;
}
#filterSideNav .productfilter
{
  border-top: 1px solid #666;
}
#filterSideNav .productfilter:last-child
{
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  padding-bottom: 15px;
}
#filterSideNav .widget-productfilter {
  border-bottom:0;
}
/*
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#fff;
}
*/
#filterSideNav ul li {
  border-bottom:0!important;
}
/*
#filterSideNav .productfilter--select {
  border:none;
}
*/
.productfilter-title
{
  padding-bottom: 10px;
  padding: 10px 0;
  display: block;
  color:#000;

}
.productfilter-title[data-toggle="collapse"]:after {
  font-family: 'Font Awesome 5 Pro';
  content: "\f106"; /* fa-angle-up  */
  float: right;
  font-size: 18px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after {
  font-family: 'Font Awesome 5 Pro';
  content: "\f107"; /* fa-angle-down  */
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
/*  align-content: flex-start;*/
  align-items: baseline;
}
.productfilter--optionlist li input
{
/*  margin-top: 4px;*/
}
.productfilter--optionlist li label
{
  padding-left: 8px;
}
.productfilter--select
{
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}

.usps__bedroomshop
{
  padding:10px 0;
  border-bottom:1px solid #dee1e1;
  font-size: 18px;
  color:#000;
}
.usps__bedroomshop .col-sm-12 div
{
  align-self: center;
}
.usps__bedroomshop .icon_wrapper
{
  margin-right: 10px;
  display: inline-block;
}
.usp_cycle div {
    display: none;
}
.usps__bedroomshop a
{
  color:#000;
}
/**/
.sidereview .rating-stars-count
{
  padding: 5px 10px;
  display: block;
}
.sidereview .rating-stars-count a
{
  display:block;
  margin: -5px 0 0 0;
}
.sidereview .ratingcomment
{
  clear: both;
  margin-top: 5px;
  background-color: #eee;
  position: relative;
}
.sidereview .ratingcomment:after
{
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 100%;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.sidereview .ratingcomment > *
{
  padding: 15px 10px;
}

/**/
.widget-inlinemultiplereviews
{
  background: url(../web/img/review-carousel-bg.jpg) no-repeat left top #466770;
  padding: 30px;
}
.widget-inlinemultiplereviews .widget-inlinereview__comment
{
  background-color: #fff;
}
.widget-inlinemultiplereviews .widget-inlinereview__comment:after
{
  border-top: 10px solid #fff;
}
.widget-inlinemultiplereviews h2,
.widget-inlinemultiplereviews .widget-inlinereview__meta b
{
  color: #fff;
}


.widget-cardgroup .card-header
{
  border-bottom:2px solid #708788;
}
@media (max-width: 1200px)
{
  .widget-cardgroup.card-columns
  {
    column-count: 2;
  }
}
@media (max-width: 576px)
{
  .widget-cardgroup.card-columns
  {
    column-count: 1;
  }
}

/**/
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 20px;
  min-height: 130px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
  font-weight: 600;
}

.widget-fullwidthimage > img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
  position: relative;
  z-index: 1;
}

.widget-fullwidthimage--withbottombar
{
  margin-bottom: clamp(10px, 180px, 18dvw);
}

.widget-fullwidthimage--withbottombar::after
{
  content: "";
  display: block;
  position: absolute;
  background-color: #ebeded;
  z-index: 0;
  height: clamp(80px, 315px, 31dvw);
  left: 0;
  right: 0;
  margin-top: clamp(-180px, -40px, -18dvw);
}

.widget-chatbanner
{
  margin: 20px 0 20px;
  padding: 20px;
  overflow: hidden;
  background: url(../web/img/chat-bg.png) no-repeat bottom right #eee;
}

@media( max-width: 992px)
{
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

@media( max-width: 767px)
{
  .widget-chatbanner
  {
    padding-right: 160px;
  }
}

@media( max-width: 500px)
{
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

.products-slider
{
  padding:50px 0;
}
.products-slider .slick-prev:before
{
    content: "\f0a8"; /* fa-arrow-circle-left */
}
.products-slider .slick-next:before
{
    content: "\f0a9"; /* fa-arrow-circle-right */
}
.products-slider .slick-next,
.products-slider .slick-prev
{
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
}

  .products-slider .slick-next
, .products-slider .slick-prev
{
  bottom: 0;

  /* for some reason there is styling generic to .slick-next/.slick-prev
     which changes very specific things. We need to cancel these. */
  top: auto;
  right: auto;
  width: auto;
  height: auto;
  transform: none;

  padding: 5px 7px;
/*  outline: 1px solid #000;*/
}

.products-slider .slick-next
{
  left: 50px;

  margin-top: 0;
}
.products-slider .slick-prev
{
  left: 10px;
  right: auto;
  transform: none;
}

.products-slider .slick-prev:before
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f104"; /* fa-angle-left */
  color:#708788;
  font-size: 36px;
}
.products-slider .slick-next:before
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f105"; /* fa-angle-right */
  color:#708788;
  font-size: 36px;
}

/* widget faq */
.widget-faq .card
{
  margin-bottom: 10px;
  border-radius: 0;
  border:1px solid #eee!important;
}
.widget-faq .card-header
{
  background-color: #f9f9f9;
  border-radius: 0;
  padding: 5px 10px;
}

.widget-faq .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;
  border-radius: 0;
  overflow:hidden;
}
.widget-faq .btn-link
{
  color:#000;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
  text-decoration: none;
}
.widget-faq .card-header .btn[aria-expanded="false"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f067"; /* fa-plus  */
  font-size: 16px;
  color: #555;
  position: absolute;
  right: 15px;
}
.widget-faq .card-header .btn[aria-expanded="true"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f068"; /* fa-minus  */
  font-size: 16px;
  color: #555;
  position: absolute;
  right: 15px;
}

/* widget collapse */
.widget-collapse .card
{
  margin-bottom: 10px;
  border-radius: 0;
  border:1px solid #eee!important;
}
.widget-collapse .card-header
{
  background-color: #f9f9f9;
  border-radius: 0;
  padding: 5px 10px;
}
.widget-collapse .card-header h4 button:hover
{
  text-decoration: none;
}

.widget-collapse .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;
  border-radius: 0;
  overflow:hidden;
}
.widget-collapse .btn-link
{
  color:#000;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
  text-decoration: none;
}
.widget-collapse .btn-link:focus
{
  box-shadow: none;
}
.widget-collapse .card-header .btn[aria-expanded="false"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f067"; /* fa-plus */
  font-size: 16px;
  color: #555;
  position: absolute;
  right: 15px;
}
.widget-collapse .card-header .btn[aria-expanded="true"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f068"; /* fa-minus  */
  font-size: 16px;
  color: #555;
  position: absolute;
  right: 15px;
}

/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";  /* fa-angle-right */
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}


/* contact*/
.widget-contact h4
{
  font-size: 18px;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  list-style: none;
  width: 100%;
  display: flex;
}
  .widget-contact li > a
, .widget-contact li > .jivo-btn
{
  display: block;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: .5em 1em;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  background-color: #fff;
  margin-bottom: 10px;
  line-height: 21px;
  transition: .2s ease;
  cursor: pointer;
  border-radius: 3px;
  color:#708788;
  border:1px solid #eee;
}
.widget-contact li strong
{
  font-size: 15px;
  color:#000;
}
  .widget-contact li > a:hover
, .widget-contact li > .jivo-btn:hover
{
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
.widget-contact .fa, .widget-contact .far, .widget-contact .fas, .widget-contact .fab
{
  font-size: 24px;
  display: block;
  float: left;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
  color:#708788;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li
{
  flex: 1 1 50%;
  padding: 0 0 20px 30px;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0;
  }
}

.widget-fullwidthimage a
{
  display: block;
}

.widget-quicklinks
{
  color: #337ab7;
}
.widget-quicklinks__title
{
  font-size: 16px;
  padding: 8px 0;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,.1);
  display: block;
}
.widget-quicklinks__title.withicon
{
  padding-left: 35px;
}

.widget-quicklinks .fa, .widget-quicklinks .far
{
  color: #708788;
  position: absolute;
  top: 9px;
  left: 10px;
  display: inline-block;
}
.widget-quicklinks__title
{
  color: #000;
}
.widget-quicklinks a:hover
{
  text-decoration: underline;
}
.widget-quicklinks ul
{
  list-style-type: none;
  margin: 0;
  padding: 10px 20px;
}
.widget-quicklinks ul > li
{
  display: block;
  position: relative;
  padding: 2px 0 2px 15px;
}
.widget-quicklinks ul > li:before
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";  /* fa-angle-right */
  display: inline-block;
  color: #708788;
  position: absolute;
  top: -2px;
  left: 0;
  font-size: 16px;
}

.widget-map
{
  padding-top: 75%;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  cursor: pointer;
}
.widget-map:after
{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-twocol__row__col .widget-map
{
  padding-top: 100%;
}
.widget-map__gmap
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-map__gmap .wh-infowindow
{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-top: -40px;
  margin-left: -20px;
}
.widget-map__gmap .wh-infowindow > *
{
  display: block;
}
.widget-map__gmap .wh-infowindow .title
{
  padding-top: 5px;
  font-size: 16px;
  line-height: 16px;

  width: 300px;
  margin-left: -130px;
}

html.wh-wrdauth--isloggedin .widget-login
{
  display:none;
}

.widget-categoryblocks
{
  padding:50px 0;
}
.widget-categoryblocks__list
{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -10px;
}
.widget-categoryblocks__link
{
  flex: none;
  width: calc(20% - 10px);
  margin: 0 0 10px 10px;
  color:#000;
  text-decoration: none;
  display: block;
  position: relative;
  font-size: clamp(16px, 18px, 1.8dvw);
  line-height: 120%;
  background-color: #e6ecec;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}
.widget-categoryblocks__link__image
{
  vertical-align: bottom;
  position: relative;
  transition: transform 300ms;
}
.widget-categoryblocks__link__label
{
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: clamp(5px,8px,8dvw) clamp(10px,15px,1.5dvw);
  min-height: clamp(32px,40px,4dvw);
  max-width: 100%;
  background-color: #fff;
}
.widget-categoryblocks__link__label > i
{
  display: inline-block;
  margin-right: 5px;
}
.widget-categoryblocks__link:hover > .widget-categoryblocks__link__label > span
{
  text-decoration: underline;
  text-underline-position: under;
}
.widget-categoryblocks__link:hover > .widget-categoryblocks__link__image
{
  transform: scale(1.1);
}
@media(max-width: 991px)
{
  .widget-categoryblocks__link
  {
    width: calc(33.333% - 10px);
  }
}
@media(max-width: 767px)
{
  .widget-categoryblocks__link
  {
    width: calc(50% - 10px);
  }
}

.categorylist__list,
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.subcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem
{
  font-size: 16px;
}
.categorylist__listitemlink:hover,
.subcategorylist__listitemlink:hover
{
  color:#000;
}
.categorylist__listitem
{
  list-style-type: none;
  padding: 2px 0;
}
.subcategorylist__listitem
{
  list-style-type: none;
  padding: 2px 0px 0 10px;
}
li.selected > .subcategorylist__list
{
  display: block;
}
li.selected > .subcategorylist__listitemlink
{
  font-weight: 400;
  text-decoration: underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink
{
  color: #000;
}


.widget-links
{
  margin-left:  calc(var(--contentarea-pad-h) * -1);
  margin-right: calc(var(--contentarea-pad-h) * -1);

  margin-top: 30px;
  margin-bottom: 30px;
}
