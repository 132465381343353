html
{
  
  --fontawesome:         'Font Awesome 5 Pro';
  --fontawesome-light:   300 14px 'Font Awesome 5 Pro';
  --fontawesome-regular: 400 14px 'Font Awesome 5 Pro';
  --fontawesome-solid:   900 14px 'Font Awesome 5 Pro';
  --fontawesome-brands:  14px 'Font Awesome 5 Brands';
  
/*
  --fontawesome:         'Font Awesome 5 Pro';
  --fontawesome-light:   300 14px 'FontAwesome';
  --fontawesome-regular: 400 14px 'FontAwesome';
  --fontawesome-solid:   900 14px 'FontAwesome';
  --fontawesome-brands:  14px 'FontAwesome';
*/

  --fa-ellipsis-h:   "\f141";

  /* Actions*/
  --fa-pencil:       "\f040";
  --fa-envelope:     "\f0e0";
  --fa-print:        "\f02f";
  --fa-search:       "\f002";
  --fa-cross:        "\f654";
  --fa-trash-alt:    "\f2ed";

  --fa-play:         "\f04b";
  --fa-pause:        "\f04c";
  --fa-stop:         "\f04d";
  /*--fa-play-circle: "f144";*/
  --fa-times:        "\f00d";
  --fa-times-circle: "\f057";


  /* Download icons */
  --fa-cloud-download:   "\f0ed";
  --fa-download:         "\f019";
  --fa-file-download:    "\f56d";
  --fa-folder-download:  "\e053";
  --fa-arrow-to-bottom:  "\f33d";




  /* Page type indicators */
  --fa-newspaper: "\f1ea";
  --fa-calendar:      "\f133";
  --fa-calendar-alt:  "\f073";
  --fa-calendar-day:  "\f783";


  /* Expand / collapse */
  --fa-minus: "\f068";
  --fa-plus:  "\f067";
  --fa-plus-circle:  "\f055";
  --fa-minus-circle: "\f056";

  --fa-minus-square: "\f146";
  --fa-plus-square:  "\f0fe";


  /* Filetypes */
  --fa-file-pdf:    "\f1c1";
  --fa-image:       "\f03e";
  /*--fa-images: "\f302";*/


  /* Social networks */
  --fa-twitch:         "\f1e8";
  --fa-twitter:        "\f099";
  --fa-twitter-square: "\f081";
  --fa-facebook:       "\f09a";
  --fa-facebook-f:     "\f39e";
  --fa-facebook-messenger: "\f39f";
  --fa-facebook-square:    "\f082";
  --fa-linkedin:    "\f08c";
  --fa-linkedin-in: "\f0e1";
  --fa-instagram:   "\f16d";
  --fa-spotify:     "\f1bc";
  --fa-whatsapp:    "\f232";
  --fa-youtube:     "\f167";



  --fa-exclamation-triangle: "\f071";


  --fa-external-link:        "\f08e";
  --fa-external-link-alt:    "\f35d";
  --fa-external-link-square: "\f14c";

  --fa-bars: "\f0c9";



  /* Arrows */
  --fa-arrow-left:    "\f060";
  --fa-arrow-right:   "\f061";
  --fa-arrow-down:    "\f063";

  --fa-arrow-alt-from-left: "\f347";
  --fa-long-arrow-right: "\f178";

  --fa-arrow-from-left: "\f343";
  --fa-arrow-to-right: "\f340";




/*
.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }
*/
  --fa-long-arrow-right: "\f178";



  /* Angle */
  --fa-angle-left:    "\f104";
  --fa-angle-right:   "\f105";
  --fa-angle-up:      "\f106";
  --fa-angle-down:    "\f107";


  /* Caret (triangle) */
  --fa-caret-down: "\f0d7";
  --fa-caret-up:   "\f0d8";


  /* Chevron */
  --fa-chevron-left:  "\f053";
  --fa-chevron-right: "\f054";
  --fa-chevron-up:    "\f077";
  --fa-chevron-down:  "\f078";

  /* Chevron in circle */
  --fa-chevron-circle-down:  "\f13a";
  --fa-chevron-circle-left:  "\f137";
  --fa-chevron-circle-right: "\f138";
  --fa-chevron-circle-up:    "\f139";

  --fa-chevron-double-left:  "\f323";
  --fa-chevron-double-right: "\f324";



  --fa-check:         "\f00c";

  --fa-clock:         "\f017";

  --fa-arrow-circle-down: "\f0ab";



  /* Circle */
  --fa-arrow-circle-left:    "\f0a8";
  --fa-arrow-circle-right:   "\f0a9";


  /* Tooltip */
  --fa-info-circle:          "\f05a";
  --fa-question-circle:      "\f059";



  --fa-user:  "\f007";
  --fa-power-off: "\f011";
  --fa-browser: "\f37e";
  /* --fa-project-diagram: "\f542"; */
  --fa-flask: "\f0c3";

  --fa-map-marker-alt: "\f3c5";
}
