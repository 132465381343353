.checkoutpage__salebar {
  display: flex;
  --countdown-sections-gap: 2px;
  --countdown-digit-font: 22px/100% "Mukta", sans-serif;
  --countdown-digit-width: 12px;
  --countdown-digit-height: auto;
}

.brs-checkout__clock {
  display: flex;
  align-items: center;
  padding: 8px 1rem;
  background: #75b325;
  color: #FFFFFF;
}

.brs-checkout__clock__icon {
  margin-right: 10px;
  height: 34px;
}

.brs-checkout__content {
  flex: 1 1 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 6px;
  row-gap: 10px;
  align-items: center;
  padding: 8px 1rem 8px 1rem;
}

.brs-checkout__slogan b {
  color: #000000;
  font-weight: bold;
}

.brs-checkout__coupon {
  flex: none;
  display: flex;
}

.brs-checkout__coupon__info {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 5px;
  height: 40px;
  background: #ECF9FF;
  border: 1px solid #DDDDDD;
  padding: 4px 0.5rem 4px 1rem;
  cursor: pointer;
}

.brs-checkout__coupon__code {
  color: #000000;
  font-weight: bold;
}

.brs-checkout__coupon__pasteicon {
  height: 21px;
}

button.brs-checkout__coupon__addbutton {
  background: none;
  border: 0;
}

@media (max-width: 800px) {
  .checkoutpage__salebar {
    flex-wrap: wrap;
    align-items: center;
    row-gap: 10px;
  }
  .brs-checkout__clock {
    flex: 1 0 auto;
  }
  .brs-checkout__content {
    display: contents;
  }
  .brs-checkout__content b {
    flex: 1000 1 auto;
    background: #75b325;
    color: #FFFFFF;
    padding-left: 1rem;
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .brs-checkout__slogan {
    display: contents;
  }
  .brs-checkout__slogan span {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
  }
  .brs-checkout__coupon {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}