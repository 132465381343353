::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.dshopsdialog {
  border: 0 none;
  padding: clamp(20px, 3dvw, 30px);
  border-radius: 10px;
  transition: transform 200ms, opacity 200ms;
  opacity: 0;
  transform: scale(0.01);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
}
.dshopsdialog--contactform {
  max-width: 360px;
}
.dshopsdialog--aftershow {
  opacity: 1;
  transform: scale(1) perspective(1000px);
}
.dshopsdialog__close {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}