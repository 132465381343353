html
{
  --panel-box-shadow: 0 .5rem 1rem #00000026 !important;
}

.categoryintro
{
  --colleft: 1fr;
  --colright: 1fr;

  margin-bottom: 20px;
}

.categoryintro__content
{
  background: #FFFFFF;
  box-shadow: var(--panel-box-shadow);
}


.categoryintro + .categoryintro
{
  margin-top: 15px;
}

.categoryintro__rating
{
  float: right;
  margin-top: 6px;

  transform: scale(1); /* override from product-tiles.scss:.productitem__rating */

/*  margin-left: 15px;*/
  margin-bottom: 8px;
}

.categoryintro__rating > .rating-stars
{
  transform: scale(0.8);
  transform-origin: right;
}

/* Als de rating getoond wordt is er minder ruimte voor de <h1>.
   Om te voorkomen dat de heading te snel meerdere regels worden,
   verlagen we de fontsize als de h1 op een rating volgt. */
.categoryintro__rating + h1
{
  font-size: 29px; /* was 32px */
}


.categoryintro--image-addshadow .categoryintro__image
{
  box-shadow: 0 .5rem 1rem #00000026!important
}

.categoryintro__usps
{
  border-top: 1px solid #DEE1E1;
  font-size: 16px; /* 18px before */
  color: #000000;

  display: flex;
  justify-content: space-between;
}

.categoryintro__usps .usp_item
{
  display: flex;
  align-items: center;
}

.categoryintro__usps .usp_item > *
{
  font-weight: normal;
}

.categoryintro__usps .usp_item img
{
  max-width:  33px;
  max-height: 25px;
  object-fit: contain;
}

/*.usps__bedroomshop*/
.categoryintro__usps .icon_wrapper
{
  margin-right: 9px; /* instead of 10px */
}


/* Workaround for not being able to use scss, nested CSS or container queries in the Webhare RTD yet */
.wh-rtd .customerservicebar
{
  position: absolute;
  right: -18px;
  bottom: 0;
  transform: translateY(50%);

  box-shadow: -1px -1px 11px #00000046 !important;
}

.wh-rtd .categoryintro:has(.customerservicebar)
{
  margin-bottom: 75px;
}





@media (max-width: 1199px)
{
  .categoryintro__content
  {
    display: flow-root;
  }

  .categoryintro__text1
  {
    grid-column: 1;
    grid-row: 1;
    padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h);
  }

  .categoryintro__text2
  {
    padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h);
  }

  .categoryintro--image-cover .categoryintro__image
  {
    width: 100%;
    min-height: 100%;
    max-height: 450px; /* prevent using to much space on the mobile screen */
    object-fit: cover;
  }

  .categoryintro--image-keepaspect .categoryintro__imagewrapper
  {
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--categoryintro-padding-h);
  }

  .categoryintro--image-keepaspect .categoryintro__image
  {
    max-width: 100%;
    height: auto;

    display: block;
    max-height: 450px; /* prevent using to much space on the mobile screen */
    margin-left: auto;
    margin-right: auto;
  }

  .categoryintro__usps
  {
    margin-bottom: var(--categoryintro-padding-v);
  }

  .categoryintro__usps
  {
    margin: var(--categoryintro-padding-v) var(--categoryintro-padding-h) var(--categoryintro-padding-v) var(--categoryintro-padding-h);
  }

  .categoryintro__content
  {
    position: relative;
  }
}



@media (min-width: 1200px)
{
  .categoryintro__usps
  {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  .categoryintro--image-cover .categoryintro__usps
  {
    grid-column: 1;
    grid-row: 2;
  }

  .categoryintro__usps
  {
    margin: 0 var(--categoryintro-padding-h) var(--categoryintro-padding-v) var(--categoryintro-padding-h);
  }
}


@media (min-width: 1200px)
{
  .categoryintro__content
  {
    display: grid;
    grid-template-columns: minmax(0, var(--colleft)) minmax(0, var(--colright));
    grid-template-rows: min-content min-content;
  }

  .categoryintro__text1
  {
    grid-column: 1;
    grid-row: 1;
    padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h);
  }

  .categoryintro__text2
  {
    grid-column: 2;
    grid-row: 1 / span 2;
    padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h) var(--categoryintro-padding-v) 0;
  }

  .categoryintro__imagewrapper
  {
    grid-column: 2;
    grid-row: 1;
  }

  .categoryintro--image-cover .categoryintro__image
  {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .categoryintro--image-cover .categoryintro__imagewrapper
  {
    grid-row: 1 / span 2;
  }

  .categoryintro--image-keepaspect .categoryintro__imagewrapper
  {
    align-self: center; /* vertical align */
    justify-content: self; /* horizontally align */
    padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h) var(--categoryintro-padding-v) 0;
  }

  .categoryintro--image-keepaspect .categoryintro__image
  {
    max-width: 100%;
    height: auto;
  }


  .categoryintro__content
  {
    position: relative;
  }
}



.rtdcontent > *:last-child
{
  margin-bottom: 0;
}
