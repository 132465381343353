#cookie-disclaimer {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  background: var(--color-theme);
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  height: 0;
  position: fixed;
  overflow: hidden;
  max-height: 100vh;
}
html.skw--productpage-for-order #cookie-disclaimer {
  display: none;
}
#cookie-disclaimer .container {
  padding-top: 5px;
}
#cookie-disclaimer a {
  text-decoration: underline;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
#cookie-disclaimer button {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}
#cookie-disclaimer button.btn-default {
  color: #fff;
}