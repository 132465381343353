@import "@mod-slaapkamerweb/webdesigns/dshopsapp/web/fonts/fontawesome/fontawesome-cssvars.css";
@import "@mod-slaapkamerweb/web/fonts/fontawesome5/css/all.min.css";

/* light, normal, semi-bold, bold */
html
{
  color: #000000;
  font-family: 'Mukta', sans-serif;

  --contentpanel-background: #FFFFFF;
  --contentpanel-pad-h:      35px;
  --contentpanel-pad-v:      35px;
  --contentpanel-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

  --contentarea-pad-top:     16px;
  --contentarea-pad-bottom:  16px;
  --contentarea-pad-h:       16px;

  --categoryintro-padding-h: 35px;
  --categoryintro-padding-v: 35px;

  --divider-color:           #dee1e1;

  --component-tag-background:   #FFFFFF;
  --component-tag-border:       1px solid #EEEEEE;
  --component-tag-font:         300 16px "Mukta", sans-serif;
  --component-tag-height:       56px;
  --component-tag-image-height: 38px;
  --component-tagslist-spacing: 15px;
}

@media (max-width: 565px)
{
  html
  {
    --categoryintro-padding-h: 25px;
  }
  .categoryintro img.wh-rtd__img
  {
    display: none;
  }
}

@media (max-width: 320px)
{
  html
  {
    --categoryintro-padding-h: 15px;
  }
}

body
{
  font-size: 16px;
  line-height: 22px;
  font-weight:300;
  color:#708788;
  font-family: 'Mukta', sans-serif;
}

h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{
    color: #000;
    font-family: 'Mukta', sans-serif;
}
h1 {
    font-size: 32px;
}
h2 {
    font-size: 24px;
}

h2.heading2_checked:before
{
  content: '\F00C'; /* fa-check */
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  color: #75b325;
  font-size: 18px;
  padding-right: 5px;
}

h3 {
    font-size: 21px;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 120%;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
a {
    color: #337ab7;
}

ul.checkmarks,
ul.angles {
    margin:0 0 10px 0;
    padding:0
}
ul.checkmarks li,
ul.angles li  {
    position: relative;
    list-style-type: none;
    padding: 0 0 0 35px;
}
ul.checkmarks li:before {
    position: absolute;
    left: 10px;
    font-family: 'Font Awesome 5 Pro';
    content: '\f00c'; /* fa-check  */
    font-size: 19px;
    top: 0;
    color: #75b325;
}

ul.angles li:before {
    position: absolute;
    left: 18px;
    font-family: 'Font Awesome 5 Pro';
    content: '\f105'; /* fa-angle-right  */
    font-size: 19px;
    top: 0;
    color: #708788;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}
.table
{
  color:#708788;
}
.table td, .table th
{
    padding: .75rem 0;
}
.wh-rtd__img,
.img-fluid
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__table .wh-rtd__img
{
  max-width: calc(100vw - 40px);
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  border-top: 0;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #584a45;
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}

.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
/*
.wh-rtd__table tr + tr > td
{
  border-top: 1px solid #dadada;
}
*/
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}

/*!
 * Bootstrap Grid
 */

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: flex;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
      flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (min-width: 576px) {
  .col-sm {
        flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
        flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
        flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}



.categoryintro
{
  margin: 0 -16px 0 -16px;
}

.categoryintro:first-child
{
  margin-top: -16px;
}


/* "Categorie tekst onder" widget */
.categorybottomtext
{
  margin: 0 -16px;
  padding: var(--categoryintro-padding-v) var(--categoryintro-padding-h);
}

.categorybottomtext > :last-child
{
  margin-bottom: 0;
}


/* Have a link widget following a "category-intro"-widget align */
.categoryintro ~ .widget-links > *
{
  --contentarea-pad-h: var(--categoryintro-padding-h);
}



.twocol__banner
{
  padding:50px 0;
}
.twocol__banner h2
{
  font-size: 42px;
  color: #fff;
  font-family: 'Source Serif Pro', serif;
  font-weight: 600;
}
.twocol__banner p
{
  color:#fff;
  font-size: 24px;
  line-height: 32px;
}
.twocol__banner .groen
{
  background: url(../web/img/banner_bg_dgroen.jpg) repeat top right;
}
.twocol__banner .bruin
{
  background: url(../web/img/banner_bg_lbruin.jpg) repeat top right
}
.twocol__banner .order-first
{
  background: #fff;
  padding-right: 3px;
}
.twocol__banner .order-last
{
  background: #fff;
  padding-left: 3px;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-sm-15 {
    width: 50%;
    float: left;
}
@media (min-width: 565px)
{
    .col-sm-15 {
        width: 50%;
        float: left;
    }
}
@media (min-width: 768px)
{
    .col-sm-15 {
        width: 33%;
        float: left;
    }
}

@media (min-width: 992px)
{
    .col-sm-15 {
        width: 33%;
        float: left;
    }
}

@media (min-width: 1200px)
{
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media(max-width: 767px)
  {
    .twocol__banner h2
    {
        font-size: 36px;
    }
    .twocol__banner p
    {
      font-size: 21px;
      line-height: 28px;
    }
}
