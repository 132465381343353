@charset "UTF-8";
html {
  --salebadge-color: #e46767;
  /*
  Jubileum sale kleuren;

  --color-gold: #D6AD51;
  --salebar-background: var(--color-gold);
  --salebar-text-color: var(--color-gold);
  --salebar-icon-color: var(--color-gold);
  */
  --salebar-background: #103959;
  --salebar-text-color: #103959;
  --salebar-icon-color: #103959;
}

.site__ctabar + .site__content .page-navpath {
  padding-top: 3px !important;
}

.site__ctabar {
  z-index: 1; /* ensure the box-shadow drops over the page content */
}

.site__ctabar__message {
  min-height: 40px;
  padding-top: 6px; /* tweak vertical-alignment of text (most browsers don't support trimming text yet) */
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--salebar-background);
  color: #FFFFFF;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.site__ctabar__message__link {
  color: inherit;
}

.site__ctabar__message__link:hover {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
}

/* also have the underline take effect when parts have been wrapped in an inline-block (to control preferred line wrapping) */
.site__ctabar__message__link > * {
  text-decoration: inherit;
}

.site__ctabar__countdown {
  height: 40px;
  padding-top: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  color: var(--salebar-text-color);
}

.site__ctabar__countdown .countdown__bar__icon {
  height: 30px;
}

.site__ctabar__countdown .countdown__bar__time {
  padding-top: 4px;
  font-size: 21px;
}

.webshop--isproductpage {
  --fa-clock-o: "";
  --fa-custom-countdown: var(--fa-clock-o);
}

.product__options {
  --countdown-sections-gap: 2px;
  --countdown-digit-font: inherit;
  --countdown-digit-width: 14px;
  --countdown-digit-height: auto;
}

.product__options__salecountdown {
  overflow: hidden; /* cut off the box-shadow at the sides */
}

.countdown__bar {
  height: 46px;
  padding-left: var(--productoptions-padding);
  padding-right: var(--productoptions-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.product__options__salecountdown__bar {
  padding-top: 1px; /* tweak vertical-alignment of text (most browsers don't support trimming text yet) */
  background-color: var(--salebar-background);
  --salebar-icon-color: #FFFFFF;
  color: #FFFFFF;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.countdown__bar__icon {
  margin-top: -1px;
  flex: none;
  height: 33px;
  aspect-ratio: 460/550;
  margin-right: 15px;
  vertical-align: middle;
  line-height: 1; /* for better vertical alignment */
}

.countdown__bar__icon::before {
  font-family: "Font Awesome 5 Pro";
  content: var(--fa-custom-countdown);
  font-size: 32px;
}

.countdown__bar__time {
  font-size: 23px;
}

.countdown__bar__label {
  margin-left: 30px;
  font-size: 19px; /* 28px */
  text-transform: uppercase;
}

a.countdown__bar__label {
  color: inherit;
  text-decoration: none;
}

a.countdown__bar__label:hover {
  text-decoration: underline;
}

.product__options__salecountdown__slogan {
  text-align: center;
  color: var(--salebar-text-color);
  padding: 7px var(--productoptions-padding);
  font: 20px var(--bs-font-sans-serif);
}

@media (max-width: 1300px) {
  .product__options {
    --countdown-digit-width: 12px;
  }
  .countdown__bar__icon {
    height: 30px;
  }
  .countdown__bar__time {
    font-size: 22px;
  }
  .countdown__bar__label {
    font-size: 17px;
  }
}
@media (max-width: 500px) {
  .product__options {
    --countdown-sections-gap: 2px;
    --countdown-digit-font: 19px var(--bs-font-sans-serif);
    --countdown-digit-width: 12px;
    --countdown-digit-height: auto;
  }
  .countdown__bar {
    height: 42px;
    padding-left: 0;
    padding-right: 0;
  }
  .countdown__bar__icon {
    margin-right: 10px;
    height: 28px;
  }
  .countdown__bar__time {
    font-size: 19px;
  }
  .countdown__bar__label {
    margin-left: 20px;
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .product__options {
    --countdown-sections-gap: 2px;
    --countdown-digit-font: 19px var(--bs-font-sans-serif);
    --countdown-digit-width: 12px;
    --countdown-digit-height: auto;
  }
  .countdown__bar__label {
    margin-left: 15px;
    font-size: 15px;
  }
  .countdown__seperator {
    line-height: 23px;
    margin: 0 -1px;
  }
}
@media (max-width: 320px) {
  .product__options {
    --countdown-sections-gap: 2px;
    --countdown-digit-font: 17px var(--bs-font-sans-serif);
    --countdown-digit-width: 10px;
    --countdown-digit-height: auto;
  }
  .countdown__bar__label {
    font-size: 12.5px;
  }
  .countdown__seperator {
    line-height: 100%;
    margin-left: -1px;
    margin-right: -1px;
  }
}