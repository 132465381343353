@media (min-width: 993px) {
  .scroll-up #header,
  .scroll-down #header {
    background-color: #fff;
  }
}
@media (max-width: 992px) {
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: translate3d(0, 0, 0);
  }
}
.mainbar-wrapper {
  position: relative;
}

.messageheader {
  background-color: #fcefbb;
  position: relative;
  overflow: hidden;
  max-height: 100dvh;
}
.messageheader__content {
  padding: 10px 20px;
  text-align: center;
  color: #000;
  font-size: clamp(15px, 16px, 1.6dvw);
  line-height: 20px;
}
.messageheader svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 10px;
}
.messageheader__reduction {
  color: #fff;
  vertical-align: middle;
  background-color: var(--red);
  font-weight: 600;
  border-radius: 10px;
  display: inline-block;
  padding: 2px 5px;
  font-size: 90%;
  margin: 0 5px;
}
.messageheader b {
  font-weight: 700;
}
.messageheader a.messageheader__blackfridaylink {
  color: inherit;
  text-decoration: none;
}
.messageheader a.messageheader__blackfridaylink:hover > span:not(.messageheader__reduction), .messageheader a.messageheader__blackfridaylink:hover > b {
  text-decoration: underline;
  text-underline-position: under;
}
.messageheader a:not(.messageheader__blackfridaylink) {
  color: inherit;
  white-space: nowrap;
  font-weight: 700;
}
.messageheader a:not(.messageheader__blackfridaylink) > span {
  text-decoration: underline;
  text-underline-position: under;
}

/*
@media (max-width: 992px)
{
*/
.scroll-down .messageheader,
.scroll-up .messageheader {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
}

/* } */
html {
  scroll-padding-top: 190px;
}
@media (max-width: 992px) {
  html {
    min-height: 20px;
  }
}

@media (min-width: 993px) {
  .scroll-down header:has(> .messageheader),
  .scroll-up header:has(> .messageheader) {
    min-height: 150px;
  }
}
header {
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 2010;
  transition: transform 300ms;
  min-height: 150px;
}
header:has(> .messageheader) {
  min-height: 190px;
}
@media (max-width: 992px) {
  header {
    min-height: 110px;
  }
  header:has(> .messageheader) {
    min-height: 110px;
  }
}
header .topbar {
  background-color: #708788;
  padding: 6px 0;
  color: #fff;
  font-size: 14px;
}
header .topbar a {
  color: #fff;
}
header .topbar .topnav {
  margin-bottom: 0;
}
header .topbar .topnav a {
  padding: 0 0.5rem;
  color: #fff;
}
@media (max-width: 1200px) {
  header #mainbar.container {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel .logo {
  display: inline-block;
  margin: 27px 0;
  transition: 0.2s;
}
header .topleftpanel .logo > img {
  height: auto;
  width: 195px;
}
header .toprightpanel #topsearchform {
  position: absolute;
  right: 14px;
  top: -12px;
  transform: translateX(0) translateY(-50%);
  max-width: 400px;
  overflow: hidden;
}
header .toprightpanel #topsearchform * {
  outline: none;
  box-sizing: border-box;
}
header .toprightpanel #topsearchform .search__wrapper {
  position: relative;
}
header .toprightpanel #topsearchform .search__wrapper .search__field {
  width: 31px;
  height: 35px;
  color: transparent;
  padding: 0.35em 30px 0.35em 0;
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
header .toprightpanel #topsearchform .search__wrapper .search__field:focus {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  width: 30vw;
  padding-left: 5px;
  color: #000;
  cursor: default;
}
header .toprightpanel #topsearchform .search__wrapper .search__field:focus ~ .search__icon {
  background-color: #fff;
  cursor: pointer;
  pointer-events: auto;
}
header .toprightpanel #topsearchform .search__wrapper .search__field::-webkit-input-placeholder, header .toprightpanel #topsearchform .search__wrapper .search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  opacity: 1;
}
header .toprightpanel #topsearchform .search__wrapper .search__field::-webkit-input-placeholder[style*=hidden], header .toprightpanel #topsearchform .search__wrapper .search__field:-moz-placeholder[style*=hidden] {
  color: #000;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
header .toprightpanel #topsearchform .search__wrapper .search__icon {
  position: absolute;
  background-color: transparent;
  top: 0;
  color: #000;
  right: 0;
  width: 36px;
  height: 30px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
}
header .toprightpanel i {
  color: #e7bd62;
}
header .toprightpanel a {
  color: #000;
}
header .toprightpanel .list-inline-item {
  position: relative;
  text-align: center;
  margin-right: 0;
}
header .toprightpanel .list-inline-item span {
  display: block;
  color: #000;
  font-size: 12px;
  width: 55px;
}
header .toprightpanel .list-inline-item span.navbar-toggler-icon {
  font-size: 20px;
}
header .toprightpanel .badge {
  background: #e7bd62;
  position: absolute;
  margin-left: -10px;
  top: -9px;
  right: 10px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px !important;
  height: 20px;
  max-width: fit-content;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 0 5px;
}
header .toprightpanel .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (min-width: 992px) {
  header .topleftpanel .logo {
    margin: 27px 0;
  }
  header .topleftpanel .logo > img {
    max-width: 230px;
  }
}
@media (max-width: 767px) {
  header .topleftpanel .logo {
    margin: 10px 0;
  }
  header .topleftpanel .logo > img {
    max-width: 230px;
  }
  header .stickynav {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  header .stickynav .logo {
    margin: 0;
  }
  header .stickynav .logo img {
    max-width: 200px;
  }
}
@media (max-width: 565px) {
  header .review_top {
    margin: 0 auto;
  }
  header .topleftpanel .logo {
    margin: 5px 0 10px 0;
  }
  header .topleftpanel .logo > img {
    max-width: 230px;
  }
  header.stickynav .logo img {
    max-width: 195px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel {
    text-align: center;
  }
  header .toprightpanel {
    margin-top: 3px;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 0;
  }
}
@media (max-width: 380px) {
  header .topleftpanel .logo {
    margin: 15px 0;
  }
  header .topleftpanel .logo > img {
    max-width: 150px;
  }
  header.stickynav .logo img {
    max-width: 150px;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 0;
  }
  header .toprightpanel {
    margin-top: 3px;
  }
  header .toprightpanel .list-inline-item span {
    font-size: 11px;
    width: 52px;
  }
}

.navbar {
  padding: 0;
  margin-top: 10px;
}

.navbar-brand {
  font-size: 1rem;
}

.navbar-toggler {
  border: none;
}

#mainnav {
  width: 100%;
  max-height: 42px;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
#mainnav .toplevelmenu__container .nav-link {
  color: #000;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #000000;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
}
#mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
  display: block;
}
#mainnav .toplevelmenu__container li {
  display: inline-block;
}
#mainnav .toplevelmenu__container li.bg-primary {
  position: relative;
  top: -2px;
  height: 43px;
}
#mainnav .toplevelmenu__container li:hover li a:hover {
  color: #000;
  text-decoration: underline;
}
#mainnav .dropdown-menu {
  min-width: 500px;
  width: max-content;
  padding: 2rem;
  margin: 0;
  background-color: #fff;
  border-radius: 0;
  border: 0;
  border-top: 5px solid #708788;
  min-height: 300px;
}
#mainnav .dropdown-menu p.normal {
  display: none;
}
#mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 20px;
  margin-bottom: 0;
}
#mainnav .dropdown-menu ul li {
  display: block;
  color: #000;
  position: relative;
  margin-left: 0;
  padding: 5px 10px;
}
#mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #000;
}
#mainnav .dropdown-menu ul li strong, #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}
@media all and (min-width: 991px) {
  #mainnav .dropdown:after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* transition: opacity .15s ease;*/
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  #mainnav .dropdown.show .nav-link,
  #mainnav .dropdown:hover .nav-link {
    position: relative;
    z-index: 12;
  }
  #mainnav .dropdown.show:after,
  #mainnav .dropdown:hover:after {
    z-index: 2;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}

@media only screen and (min-width: 911px) and (max-width: 1200px) {
  header #mainnav .nav-link {
    padding: 0.5rem;
  }
}