.row--bottom {
  padding-bottom: 38px; /* space for chat tab at bottom */
}

footer .newsletter__row {
  width: 100%;
  background-color: #708788;
  color: #fff;
  padding: 20px 0;
}
footer .newsletter__row h3 {
  color: #fff;
}
footer .newsletter__row input[type=email] {
  height: 38px;
}
footer .newsletter__row form {
  scroll-margin-top: 200px;
}
footer .footer__widget.nav ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px;
}
footer .footer__widget.nav li {
  display: table-row;
}
footer .footer__widget.nav li:before {
  width: 1.8em;
  font-size: 14px;
  line-height: 28px;
  font-family: "Font Awesome 5 Pro";
  content: "\f105"; /* fa-angle-right */
  display: table-cell;
  padding-left: 7px;
}
footer .footer__widget .widget-customerservice {
  border: none !important;
  box-shadow: none;
  padding: 0 !important;
}
footer .footer__widget .widget-customerservice h2,
footer .footer__widget .widget-customerservice p {
  display: none;
}
footer .footer__widget .widget-customerservice .customerservice__buttons {
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
}
footer .footer__widget .widget-customerservice .customerservice__buttons li {
  list-style: none;
  width: 100%;
  display: flex;
}
footer .footer__widget .widget-customerservice .customerservice__buttons li a,
footer .footer__widget .widget-customerservice .customerservice__buttons li .jivo-btn {
  display: block;
  align-items: center;
  width: 100%;
  padding: 0.5em 1em;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  background-color: #363636;
  margin-bottom: 10px;
  border-radius: 4px;
  line-height: 21px;
  transition: 0.2s ease;
  cursor: pointer;
}
footer .footer__widget .widget-customerservice .customerservice__buttons li a:hover,
footer .footer__widget .widget-customerservice .customerservice__buttons li .jivo-btn:hover {
  background-color: #4f4f4f;
}
footer .footer__widget .widget-customerservice .customerservice__buttons li a i,
footer .footer__widget .widget-customerservice .customerservice__buttons li .jivo-btn i {
  font-size: 24px;
  display: block;
  float: left;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
}
footer #ondermenu {
  padding: 60px 0;
  background: #3e3e3e;
  color: #fff;
}
@media (max-width: 767px) {
  footer #ondermenu {
    padding: 40px 0;
  }
  footer #ondermenu .row {
    background: #333;
    margin-right: 0;
    margin-left: 0;
    border-radius: 6px;
  }
  footer #ondermenu .col-md-6:first-child {
    margin-top: 15px;
  }
  footer #ondermenu .col-md-6:last-child {
    border-bottom: 0px;
  }
  footer #ondermenu .col-md-6 {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #444;
  }
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
@media (max-width: 767px) {
  footer .bottomnav ul {
    text-align: center;
  }
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li + li:before {
  content: "|";
  padding: 0 10px;
  color: #ddd;
  display: inline-block;
}
footer h5 {
  color: #fff;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
}
footer a, footer h4 {
  color: #fff;
}
footer a:hover {
  color: #fff;
}
footer #social-media {
  margin: 20px 0 0;
}
footer #social-media .footer__social__links {
  font-size: 24px;
}
footer #social-media .footer__social__links a {
  display: inline-block;
  text-decoration: none;
  margin-right: 10px;
}
footer .badge-default {
  border: 1px solid #636363;
  background: transparent;
  color: #eee;
  font-size: 12px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 5px 0;
  padding: 7px;
  transition: all 0.2s ease;
}
footer .badge-default:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-decoration: none;
}
footer a.badge-default {
  font-weight: 400;
}
footer .form-control {
  border: 0 solid #ccc;
  border-radius: 0;
}
footer .ratingcomment {
  margin: 0 0 10px;
  padding: 10px;
  position: relative;
  background-color: #363636;
}
footer .ratingcomment:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #363636;
  bottom: -9px;
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
}
@media (max-width: 767px) {
  footer .ratingcomment {
    background-color: #444;
  }
  footer .ratingcomment:after {
    border-top: 10px solid #444;
  }
}
footer .bottomnav,
footer .bottomline {
  padding: 20px 0;
}
footer .bottomnav a,
footer .bottomline a {
  color: #708788;
}
footer .footer__tags {
  background-color: #363636;
  overflow: hidden;
  padding: 30px 0;
  text-align: center;
}
footer .footer__tags h5 {
  margin-bottom: 10px;
  padding: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}