.product_listing,
.combinesection__products {
  --productsgrid-spacing-h: 15px;
  --productsgrid-spacing-v: 15px;
  --producttile-background: #FFFFFF;
  --producttile-padding-v: 20px;
  --producttile-padding-h: 25px;
  --producttile-minheight: 0px;
  --producttile-title-color: #000000;
  --producttile-title-font: 19px/22px Mukta, sans-serif;
  --producttile-description-color: #708788;
  --producttile-description-font: 16px/22px Mukta, sans-serif;
  --producttile-image-aspectratio: 10 / 8;
  --producttile-image-aspecttop: 80%;
}

.webshop-search .product_listing,
.webshop--iscategorypage .product_listing {
  --producttile-minheight: 133px;
}

.productsgrid--categories {
  --producttile-background: #708788;
  --producttile-padding-v: 10px;
  --producttile-padding-h: 10px;
  --producttile-minheight: 0px;
  --producttile-title-color: #FFFFFF;
  --producttile-image-aspectratio: 7 / 5;
  --producttile-image-aspecttop: 71.4286%;
}

.combinesection__products {
  --producttile-padding-v: 10px;
  --producttile-padding-h: 10px;
  --producttile-minheight: 0px;
}

@media (max-width: 767px) {
  .productsgrid {
    --productsgrid-spacing-h: 12px;
    --productsgrid-spacing-v: 12px;
  }
}
@media (max-width: 479px) {
  .productsgrid {
    --productsgrid-spacing-h: 12px;
    --productsgrid-spacing-v: 15px;
    --producttile-padding-v: 10px;
    --producttile-padding-h: 10px;
    --producttile-minheight: 0px;
    --producttile-title-font: 16px/18px Mukta, sans-serif;
    --producttile-description-font: 14px/17px Mukta, sans-serif;
  }
}
@media (max-width: 420px) {
  .productsgrid--categories {
    --producttile-title-fontsize: 12px;
  }
}
/*
Current grid setup for products widget:

div.product_listing mobile-order-top webshop-products
  ul.productsgrid webshop-productlist-dynprice


Mini listing:

div.product_listing
  div.productsgrid
    div.container
      div.products-slider
        (own copy of product-tile witty)
*/
/*
Grid & filters layout
The productsgrid is 1, 2 or 3 columns
*/
.productsgrid {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: var(--productsgrid-spacing-h);
  row-gap: var(--productsgrid-spacing-v);
}
@media (max-width: 767px) {
  .productsgrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 479px) {
  .productsgrid {
    grid-template-columns: minmax(0, 1fr);
  }
}

/* The product page column where RTD content is used has a max size of 752px.
   (and when switching to tablet it's limited to 690px)
   This is smaller than the 976 the category page (productsoverview) gets.
   So we need to limit productgrids to 2-column on the product page.

   FIXME: in the future we might use container queries.
*/
.webshop--isproductpage .product_listing {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.webshop--isproductpage .customblok .p-3 .product_listing {
  margin-top: 0;
  margin-bottom: 0;
}
.webshop--isproductpage .productsgrid {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  --producttile-padding-v: 15px;
  --producttile-padding-h: 15px;
}
@media (max-width: 530px) {
  .webshop--isproductpage .productsgrid {
    grid-template-columns: minmax(0, 1fr);
  }
}

/* The categories grid always is 2 or 3 columns */
.productsgrid--categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}
@media (max-width: 767px) {
  .productsgrid--categories {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

/* "Products slider"-widget carrousel */
.webshop-products.webshop-products-mini .product_listing {
  background: #f9f9f9;
}

.product_listing.webshop-products {
  position: relative;
  background-color: #f9f9f9;
}
.product_listing.webshop-products .webshop-products--ordering-container {
  text-align: right;
  margin-bottom: 10px;
  margin-right: 10px;
  padding-top: 7px;
}
.product_listing.webshop-products .webshop-products--ordering-container .form-control {
  width: auto;
  display: inline-block;
  margin-left: 10px;
}

.productsgrid__product--hidden {
  display: none;
}

.product__related .product_listing {
  background: #f9f9f9;
  padding: 25px 25px;
}

@media (max-width: 768px) {
  .webshop-products.webshop-products-mini .product_listing .container {
    max-width: 100vw;
  }
}
.webshop-products.webshop-products-mini .products-slider {
  padding-bottom: 50px;
}

/*
.webshop-products-mini
  .product_listing
    .container
      .products-slider
        .slick-list.draggable
*/
.products-slider .slick-list.draggable {
  padding-top: 20px !important;
  padding-bottom: 50px !important;
  margin-bottom: -30px;
}
.products-slider .productsgrid__product {
  padding: 0 15px;
}

@media (min-width: 480px) {
  .product__related .productsgrid,
  .combinesection__products .productsgrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    --producttile-padding-v: 10px;
    --producttile-padding-h: 10px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .product__related .productsgrid,
  .combinesection__products .productsgrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .product__related .productsgrid,
  .combinesection__products .productsgrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 767px) and (min-width: 480px) {
  .product__related .productsgrid,
  .combinesection__products .productsgrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}