@charset "UTF-8";
@import "~dompack/browserfix/reset.css";
@import "~sidebarjs/dist/sidebarjs.css";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/fonts/slick.eot");
  src: url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/fonts/slick.woff") format("woff"), url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("@mod-slaapkamerweb/node_modules/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

html {
  --color-theme: #000000;
}

html:not(.webshop--isproductpage) body {
  background-color: #f9f9f9;
}

body {
  background-color: #fff;
  padding-bottom: 50px;
}
body .mfp-bg {
  z-index: 2042;
}
body .mfp-wrap {
  z-index: 2043;
}
body .mfp-preloader {
  z-index: 2044;
}

body, main.site-wrapper {
  min-height: 100vh;
}

html.skw--productpage-for-order {
  background-color: #ffffff;
}
html.skw--productpage-for-order body {
  background-color: #ffffff;
}

.fab.fa-twitter {
  display: inline-block;
}
.fab.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

.main--margintop {
  margin-top: 1.5rem;
}

.main--whitebg {
  background-color: #fff !important;
  padding: 1rem;
}

.site__content {
  flex: 1 1 auto;
}

.skw__cornerimage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .skw__cornerimage {
    width: 280px;
    height: auto;
  }
}

main > nav {
  margin-top: -10px;
}

.lp {
  padding: 0 25px;
  line-height: 1.7em;
}

main > *:last-child {
  margin-bottom: 50px;
}

main .pageimage {
  margin: 0 -20px 20px;
}
main .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.breadcrumb {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  background: none;
  padding-left: 0;
  margin-bottom: 0;
  color: #acb7b7;
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb a {
  font-size: 14px;
  color: #acb7b7;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 5px;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  padding-right: 5px;
  content: "/";
  font-size: 12px;
  color: #acb7b7;
}

.contents--spaceright {
  padding-right: 30%; /* 825 */
  position: relative;
}

.contents__imageright {
  width: calc(30% - 20px);
  min-height: 20px;
  position: absolute;
  top: 7px;
  right: 0;
}
.contents__imageright > img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .contents--spaceright {
    padding-right: 0;
  }
  .contents__imageright {
    display: none;
  }
}
.rating-stars {
  background-image: url(web/img/stars-review-grey.png);
  display: inline-block;
  height: 22px;
  position: relative;
  width: 117px;
}
.rating-stars > .inner {
  background-image: url(web/img/stars-review-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.bg-dark {
  background-color: #3e3e3e !important;
}

.bg-primary {
  background-color: #708788 !important;
}

.bg-secondary {
  background-color: #3e3e3e !important;
}

.bg-dark h2, .bg-dark h3,
.bg-dark p,
.bg-dark a,
.bg-dark li,
.bg-primary h2,
.bg-primary h3,
.bg-primary p,
.bg-primary a,
.bg-primary li,
.bg-secondary h2,
.bg-secondary h3,
.bg-secondary p,
.bg-secondary a,
.bg-secondary li {
  color: #fff;
}

.pagetags {
  margin: 30px 0;
}
.pagetags ul {
  list-style-type: none;
  margin: 0 0 0 -6px;
  padding: 0;
}
.pagetags ul li {
  display: inline-block;
  margin-bottom: 6px;
  margin-left: 6px;
}
.pagetags ul li a {
  display: inline-block;
  color: #b5aba7;
  border-color: #b5aba7;
  padding: 6px 12px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: background-color 0.2s, border-color 0.2s;
  border: 1px solid transparent;
}
.pagetags ul li a:hover {
  background-color: #fff;
  border-color: #584a45;
}

.contentarea {
  position: relative;
  z-index: 0;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  border-radius: 0;
}

.btn-primary {
  background-color: #e7bd62;
  border-color: #e7bd62;
  color: #fff !important;
  border-color: #e7bd62;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #eec670;
  border-color: #eec670;
  text-decoration: none;
  color: #fff;
}

.btn-secondary {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #fff;
  border-color: #3e3e3e;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  text-decoration: none;
}

.btn-green {
  background-color: #75b325;
  border-color: #75b325;
  color: #fff;
  border-color: #75b325;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-green:hover,
.btn-green:focus {
  background-color: #84c92a;
  border-color: #84c92a;
  text-decoration: none;
  color: #fff;
}

.btn-success {
  background-color: rgb(68, 187, 110);
  border-color: rgb(68, 187, 110);
}

.btn-info {
  background-color: #4185d6;
}

.card-body {
  padding: 1rem;
}

aside {
  margin: 0 0 20px;
}

aside .aside__title {
  font-size: 18px;
  color: #000000;
  line-height: 1.1;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.1;
}

aside.usps .aside__title {
  display: block;
  padding: 20px 20px 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.1;
  text-align: center;
  background: url(web/img/prodsmall-footer.gif) repeat-x 0 100%;
}

aside.usps ul li:first-child {
  position: relative;
  list-style-type: none;
  background: url(web/img/prodsmall-footer.gif) repeat-x 0 100%;
  padding: 8px 20px 8px 50px;
}

aside.usps span.aside__title small {
  text-transform: none;
  color: #000000;
  font-size: 21px;
  font-weight: 400;
}

aside.usps ul {
  display: block;
  margin: 0;
  padding: 0;
}

aside.usps ul li:first-child {
  font-weight: 600;
  font-size: 16px;
}

aside.usps ul li {
  position: relative;
  list-style-type: none;
  padding: 8px 20px 8px 50px;
}

aside.usps ul li b {
  font-size: 16px;
}

aside.usps ul li a {
  color: #000000;
}

aside.usps ul li:before {
  position: absolute;
  left: 20px;
  font-family: "Font Awesome 5 Pro";
  content: "\f00c"; /* fa-check */
  color: #75b325;
  font-size: 21px;
  top: 10px;
}

aside .mm-rating,
.reviewtotals .mm-rating {
  display: block;
  font-size: 24px;
  margin: 8px 0 -3px -2px;
}

aside .mm-recensie,
.reviewtotals .mm-recensie {
  background-color: #708788;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  width: 50px;
  height: 50px;
  float: left;
}

aside .mm-rating-txt,
aside .mm-rating-txt a,
.reviewtotals .mm-rating-txt,
.reviewtotals .mm-rating-txt a {
  color: #000000;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}

#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sidenav > ul {
  margin-top: -5px;
}
#sidenav li {
  display: block;
}
#sidenav li li.active a {
  font-weight: 400;
  text-decoration: underline;
}
#sidenav a {
  display: block;
  text-decoration: none;
  color: #000;
}
#sidenav a:hover {
  text-decoration: underline;
}
#sidenav > ul > li > a {
  font-weight: 400;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}
#sidenav > ul > li > ul > li li {
  padding-left: 15px;
}
#sidenav li > ul a {
  padding: 5px 0 3px 0;
  position: relative;
}

.contents__catpath {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  color: #a89895;
}

.contents__catpathelt {
  display: inline-block;
}

.contents__catpathelt + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}

.summary .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.summary ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.summary ul li {
  flex: 0 1 50%;
  max-width: 50%;
  padding-right: 10px;
  padding-bottom: 20px;
}
.summary ul li:nth-child(2n) {
  padding-left: 10px;
  padding-right: 0;
}
.summary ul li .image > img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
.summary ul li .title {
  padding: 15px;
  font-size: 20px;
  font-weight: normal;
  line-height: 120%;
}
.summary ul li .description {
  padding: 15px;
  background-color: #f5f5f5;
}
@media (max-width: 500px) {
  .summary ul {
    display: block;
  }
  .summary ul li {
    max-width: 100%;
    flex: none;
    padding-right: 0;
  }
  .summary ul li:nth-child(2n) {
    padding-left: 0;
  }
}
.summary ul .button {
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 30px;
  display: inline-block;
  line-height: 28px;
  position: relative;
  padding: 0 10px 0 25px;
  white-space: nowrap;
  background-color: #fff;
}
.summary ul .button > span {
  position: absolute;
  top: 8px;
  left: 10px;
}
.summary ul a {
  display: block;
  text-decoration: none;
  color: inherit;
  border: 1px solid #f5f5f5;
}
.summary ul a > * {
  display: block;
}
.summary ul a:hover .button {
  background-color: #e6e6e6;
}

.webshop-account__orders {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.webshop-account__orders .webshop-account__ordertotal:before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.webshop-account__orders td, .webshop-account__orders th {
  padding: 5px 20px 5px 0;
  white-space: nowrap;
}
.webshop-account__orders .webshop-account__ordertotal {
  text-align: right;
  width: 100px;
}
.webshop-account__orders .webshop-account__orderrow:nth-child(2n-1) td {
  background-color: #fafafa;
}

.webshop-account__customerfieldset {
  margin-bottom: 25px;
}

.webshop-account__customerfieldlabel {
  min-width: 150px;
}

.webshop-account__customerfieldlabel:after {
  content: ": ";
}

.webshop-account__fullbillingaddress .webshop-account__ordertotal:before {
  content: "€ ";
}

.btn-default {
  border-color: #ccc;
  transition: all 0.5s;
}

.btn-default.btn-outline:hover {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}

.submenu:not(.d-none) {
  display: none;
}

/* mobile left sidemenu: */
#mainSideNav,
#filterSideNav {
  visibility: hidden;
  border-top: 0;
}
#mainSideNav .close-btn,
#filterSideNav .close-btn {
  width: 42px;
  height: 42px;
  display: block;
  float: right;
  width: 42px;
  height: 41px;
  display: block;
  padding: 5px 5px;
}
#mainSideNav .close-btn:before,
#filterSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 45% auto 0 auto;
  height: 1px;
  background: #000;
  display: block;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#mainSideNav .close-btn:after,
#filterSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 1px;
  margin-top: -1px;
  background: #000;
  display: block;
  transform: translateY(-1px) rotate(-45deg);
  transition: all 300ms;
}
#mainSideNav ul,
#filterSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainSideNav [sidebarjs-container],
#mainSideNav [sidebarjs-backdrop],
#filterSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-backdrop] {
  transition: all 0.3s ease;
}
#mainSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-container] {
  background: #fff;
  overflow-y: auto;
}
#mainSideNav [sidebarjs-container] .nav-link,
#filterSideNav [sidebarjs-container] .nav-link {
  font-size: 14px;
}
#mainSideNav [sidebarjs-container] a,
#mainSideNav [sidebarjs-container] h3,
#filterSideNav [sidebarjs-container] a,
#filterSideNav [sidebarjs-container] h3 {
  color: #000;
}
#mainSideNav.sidebarjs--is-visible,
#filterSideNav.sidebarjs--is-visible {
  visibility: visible;
}
#mainSideNav .has-submenu,
#filterSideNav .has-submenu {
  position: relative;
}
#mainSideNav .has-submenu .nav-link,
#filterSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 14px;
}
#mainSideNav .has-submenu > .submenu-toggle,
#filterSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: block;
  float: right;
  width: 42px;
  height: 39px;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  transition: all 200ms ease-out;
  box-sizing: border-box;
}
#mainSideNav .has-submenu > .submenu-toggle:last-child,
#filterSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#mainSideNav .has-submenu > .submenu-toggle:before,
#filterSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 1px;
  background: #000;
  display: block;
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:after,
#filterSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 1px;
  background: #000;
  display: block;
  transform: translateY(-1px) rotate(-90deg);
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:hover,
#filterSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:before,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  transform: rotate(-180deg);
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:after,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  transform: translateY(-1px) rotate(-360deg);
  opacity: 0;
}
#mainSideNav ul li,
#filterSideNav ul li {
  border-bottom: 1px solid #ced4da;
  margin-top: -1px;
}
#mainSideNav ul li ul li,
#filterSideNav ul li ul li {
  border: none;
}
#mainSideNav ul.active li,
#filterSideNav ul.active li {
  border: none;
}
#mainSideNav ul li.active,
#filterSideNav ul li.active {
  border-top: 0;
}
#mainSideNav ul.submenu,
#filterSideNav ul.submenu {
  margin-left: 0.5rem;
}
#mainSideNav .btn-link,
#mainSideNav .btn-link:active,
#mainSideNav .btn-link:focus,
#mainSideNav .btn-link:hover,
#filterSideNav .btn-link,
#filterSideNav .btn-link:active,
#filterSideNav .btn-link:focus,
#filterSideNav .btn-link:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  color: #fff;
}

@media (min-width: 992px) {
  #mainSideNav {
    display: none;
  }
}
.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 0px 60px 0 rgba(0, 0, 0, 0.22);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.mfp-popup {
  position: relative;
  background: url(web/img/payment/in3-logo.png) bottom right no-repeat #fff;
  padding: 20px;
  width: auto;
  max-width: 600px;
  margin: 20px auto;
}

#cookie-disclaimer {
  background-color: #3e3e3e !important;
}