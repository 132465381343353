@media (min-width: 992px) {
  .skw__optionstoggle {
    display: none;
  }
}
@media (max-width: 993px) {
  .webshop-listcart__options {
    transition: max-height 0.3s;
    max-height: 400px;
    overflow: hidden;
  }
  .skw__optionstoggle:not(.skw__optionstoggle--open) + .webshop-listcart__options {
    max-height: 0;
  }
}
.skw__optionstoggle {
  cursor: pointer;
}

.skw__optionstoggle:not(.skw__optionstoggle--open) .skw__optionstoggle--ifopen,
.skw__optionstoggle.skw__optionstoggle--open .skw__optionstoggle--ifclosed {
  display: none;
}