/*
This is used for:
- product widget
  "http://www.webhare.net/xmlns/webshop/widgets/products"

    - Webshop module uses this for the SearchPageBase
    - Webshop module uses this for the WebshopCategoryPage (overviewpage.whlib)
    - Bedroomshop uses this for RTD type "http://www.slaapkamerweb.nl/xmlns/bedroomshop/defaultrtdtype"
    - Bedroomshop uses this for RTD type "http://www.slaapkamerweb.nl/xmlns/bedroomshop/richdocumentfiletype"
    - Bedroomshop uses this for "related products"

- category widget
  "http://www.webhare.net/xmlns/webshop/widgets/categories"

- "Product slider"-widget (aka .webshop-products-mini)
  "http://www.slaapkamerweb.nl/xmlns/bedroomshop/widgets/productsmini"
*/
.productitem {
  display: block;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--producttile-background);
  color: var(--producttile-title-color);
  font: inherit;
  text-decoration: none;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.2s ease-in-out 0s;
}

.productitem__specialsale {
  line-height: 20px;
  text-align: center;
}
.productitem__specialsale__title {
  font-weight: 700;
  font-size: 105%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.productitem__specialsale__description {
  font-size: 85%;
}
.productitem__specialsale__reduction {
  color: #fff;
  margin-left: 5px;
  background-color: var(--red);
  font-weight: 600;
  border-radius: 10px;
  display: inline-block;
  padding: 0 6px;
  font-size: 85%;
}

#product_tab1 .productitem,
#productomschrijving .productitem {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.productitem:hover {
  text-decoration: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  color: var(--producttile-title-color);
  z-index: 1;
  transform: scale(1.05);
}

.productitem .webshop-product__add, .productitem .productitem__viewbutton {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.productitem:hover .webshop-product__add, .productitem:hover .productitem__viewbutton {
  opacity: 1;
}

.combinesection__products .webshop-product__add {
  position: absolute;
  right: 0;
  bottom: 0;
}

.products-slider .slick-track {
  display: flex;
}

.products-slider .slick-slide {
  height: auto; /* we get it through our flex container */
}

.products-slider .slick-slide > div {
  min-height: 100%;
  height: 1%;
}

.products-slider .productsgrid__product {
  margin-bottom: 0 !important;
}
.products-slider .productsgrid__product {
  flex: unset;
  max-width: none;
  height: 100%;
}

.productitem__image {
  flex: none;
  /* Enforce a 10 / 8 aspect ratio for iOS < 15 (but min iOS 10.3 / iPad gen 4 2012) */
  display: grid;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.productitem__image::before {
  content: "";
  padding-top: var(--producttile-image-aspecttop);
  grid-column: 1;
  grid-row: 1;
}
.productitem__image > * {
  grid-column: 1;
  grid-row: 1;
}
.productitem__image img {
  max-width: 100%;
  height: initial;
  transition: transform 0.3s;
}
@media (min-width: 1200px) {
  .productitem__image .ambience__image:hover img:last-child {
    opacity: 1;
    visibility: visible;
  }
  .productitem__image .ambience__image:hover img:first-child {
    opacity: 0;
    visibility: hidden;
  }
  .productitem__image .ambience__image > img {
    transition: 0.5s ease all;
    position: absolute;
    top: 0;
    left: 0;
  }
  .productitem__image .ambience__image > img:last-child {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1199px) {
  .productitem__image .ambience__image > img:last-child {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

.productitem--category .productitem__body {
  text-align: center;
}

.productitem--category:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.productitem--category:hover .productitem__image img {
  transform: scale(1.1);
}

.productitem__body {
  padding: 0 var(--producttile-padding-h) var(--producttile-padding-v) var(--producttile-padding-h);
  min-height: var(--producttile-minheight);
  flex: 1 1 auto;
}
.productitem__body > span {
  display: block;
}

.productitem__variations {
  margin-top: 5px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 17px;
}

.productitem__variation {
  flex: none;
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
}

.productitem__title:first-child {
  margin-top: var(--producttile-padding-v);
}

.productitem__title {
  font: var(--producttile-title-font);
  font-weight: 400;
}

.productitem__rating {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transform: scale(0.9);
  transform-origin: left;
}

.productitem__rating__reviewcount {
  margin-left: 10px;
  padding-top: 5px;
}

.productitem__description {
  margin-top: 10px;
  color: var(--producttile-description-color);
  font: var(--producttile-description-font);
}

.productitem__keyelements {
  margin-top: 12px;
  border-top: 1px solid #dee1e1;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #dee1e1;
  color: var(--producttile-description-color);
  font: var(--producttile-description-font);
}

.productitem__keyelements img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 10px;
}

.productitem__keyelements th {
  padding-right: 15px;
  font-weight: normal;
  text-align: left;
  vertical-align: baseline;
  padding-top: 5px;
  padding-bottom: 5px;
}

.productitem__keyelements td {
  word-break: break-word;
  vertical-align: baseline;
}

.productitem__footer {
  padding: 10px 10px var(--producttile-padding-v) var(--producttile-padding-h);
  position: relative;
  flex: 0 1 auto;
  display: flex;
}
.productitem__footer .productitem__price {
  align-self: end;
}

.productitem__viewbutton {
  margin-left: auto;
  align-self: baseline;
  margin-top: -4px;
  opacity: 0;
}
@media (max-width: 991px) and (min-width: 480px) {
  .productitem__viewbutton {
    opacity: 1;
  }
}

.productitem__price {
  height: 45px; /* prevent weird alignment of prices between blosk with and without discount */
}

.productitem__baseprice > .price {
  display: inline-block;
  color: #a0a8ab;
  font-size: 19px;
  margin-right: 5px;
  font-weight: 400;
  background: url(../../web/img/vanbg.png) no-repeat 50%;
}
.productitem__baseprice > .price:before {
  content: "";
  display: inline-block;
  padding-right: 2px;
}

.productitem__nowprice > span {
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
}
.productitem__nowprice > span:before {
  content: "";
  display: inline-block;
  padding-right: 2px;
}

.productitem__baseprice > span.pricemultiple,
.productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}

.productitem__discountslogan {
  color: #75b325;
  font-weight: normal;
}

.productitem__hoverfooter {
  display: none;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 25px));
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  background: #e9e7e3;
  color: #000000;
  font: var(--producttile-description-font);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
}
.productitem__hoverfooter--specialsale {
  height: 65px;
  transform: translateY(calc(100% - 25px));
}

@media (max-width: 479px) {
  .productitem__hoverfooter {
    transform: translateY(calc(100% - 5px));
  }
}
/* Er er een discount actief is wordt alle gereserveerde hoogte
   gebruikt door de prijsweergave. Dan kunnen we niet meer
   de hoverfooter iets omhoog schuiven want dan gaat het "plakken"
   aan de prijs. */
.product-tile--discounted .productitem__hoverfooter {
  transform: translateY(calc(100% - 5px));
}

.productitem:hover .productitem__hoverfooter {
  display: flex;
}

.productitem__hoverfooter .icon-checkmark {
  background: #4bae4f;
  color: #FFFFFF;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-right: 5px;
}

@media (max-width: 479px) {
  .productitem__footer .productitem__viewbutton {
    opacity: 1;
  }
  .productitem__price {
    height: auto;
    align-items: center;
  }
  .productitem__price .productitem__baseprice {
    color: #a0a8ab;
    font-size: 16px;
  }
  .productitem__price .productitem__baseprice .price {
    font-size: 16px;
  }
  .productitem__nowprice > span {
    font-size: 21px;
  }
}
.productitem.ribbon:before {
  width: 100px;
  height: 30px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 10px;
  left: -5px;
  z-index: 10;
  pointer-events: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.productitem.korting-20-png:before, .productitem.ribbon-20p-korting-png:before {
  background: url("../../web/img/ribbons/ribbon-20p-korting.png") no-repeat;
  width: 129px;
}
.productitem.korting-40-png:before, .productitem.ribbon-40p-korting-png:before {
  background: url("../../web/img/ribbons/ribbon-40p-korting.png") no-repeat;
  width: 129px;
}
.productitem.ribbon-actie-png:before, .productitem.ribbon-actie-oranje-png:before, .productitem.ribbon-actie-groen-png:before, .productitem.ribbon-actie-rood-png:before {
  background: url("../../web/img/ribbons/ribbon-actie-oranje.png") no-repeat;
  width: 73px;
}
.productitem.ribbon-nieuw-png:before, .productitem.ribbon-nieuw-oranje-png:before, .productitem.ribbon-nieuw-groen-png:before, .productitem.ribbon-nieuw-rood-png:before {
  background: url("../../web/img/ribbons/ribbon-nieuw-oranje.png") no-repeat;
  width: 85px;
}
.productitem.ribbon-topper-png:before, .productitem.ribbon-topper-groen-png:before, .productitem.ribbon-topper-rood-png:before, .productitem.ribbon-topper-oranje-png:before {
  background: url("../../web/img/ribbons/ribbon-topper-oranje.png") no-repeat;
  width: 91px;
}