/* Productlijn Imperial*/
/* h1-100 */
.productline_imperial.hm90-h1,
.productline_imperial.hm100-h1 {
  background-image: url('./imperial/h1-100/h1-100-manual.png');
}

.productline_imperial.a90-h1,
.productline_imperial.a100-h1 {
  background-image: url('./imperial/h1-100/h1-100-a.png');
}

.productline_imperial.b90-h1,
.productline_imperial.b100-h1 {
  background-image: url('./imperial/h1-100/h1-100-b.png');
}

.productline_imperial.c90-h1,
.productline_imperial.c100-h1 {
  background-image: url('./imperial/h1-100/h1-100-c.png');
}

.productline_imperial.d90-h1,
.productline_imperial.d100-h1 {
  background-image: url('./imperial/h1-100/h1-100-d.png');
}

.productline_imperial.el90-h1,
.productline_imperial.el100-h1 {
  background-image: url('./imperial/h1-100/h1-100-el.png');
}

.productline_imperial.er90-h1,
.productline_imperial.er100-h1 {
  background-image: url('./imperial/h1-100/h1-100-er.png');
}

.productline_imperial.fl90-h1,
.productline_imperial.fl100-h1 {
  background-image: url('./imperial/h1-100/h1-100-fl.png');
}

.productline_imperial.fr90-h1,
.productline_imperial.fr100-h1 {
  background-image: url('./imperial/h1-100/h1-100-fr.png');
}

.productline_imperial.g90-h1,
.productline_imperial.g100-h1 {
  background-image: url('./imperial/h1-100/h1-100-g.png');
}

.productline_imperial.h90-h1,
.productline_imperial.h100-h1 {
  background-image: url('./imperial/h1-100/h1-100-h.png');
}

.productline_imperial.i90-h1,
.productline_imperial.i100-h1 {
  background-image: url('./imperial/h1-100/h1-100-i.png');
}

.productline_imperial.j90-h1,
.productline_imperial.j100-h1 {
  background-image: url('./imperial/h1-100/h1-100-j.png');
}

.productline_imperial.s90-h1,
.productline_imperial.s100-h1 {
  background-image: url('./imperial/h1-100/h1-100-s.png');
}

/* h1-50 */
.productline_imperial.hm45-h1,
.productline_imperial.hm50-h1 {
  background-image: url('./imperial/h1-50/h1-50-manual.png');
}

.productline_imperial.a45-h1,
.productline_imperial.a50-h1 {
  background-image: url('./imperial/h1-50/h1-50-a.png');
}

.productline_imperial.b45-h1,
.productline_imperial.b50-h1 {
  background-image: url('./imperial/h1-50/h1-50-b.png');
}

.productline_imperial.c45-h1,
.productline_imperial.c50-h1 {
  background-image: url('./imperial/h1-50/h1-50-c.png');
}

.productline_imperial.d45-h1,
.productline_imperial.d50-h1 {
  background-image: url('./imperial/h1-50/h1-50-d.png');
}

.productline_imperial.g45-h1,
.productline_imperial.g50-h1 {
  background-image: url('./imperial/h1-50/h1-50-g.png');
}

.productline_imperial.i45-h1,
.productline_imperial.i50-h1 {
  background-image: url('./imperial/h1-50/h1-50-i.png');
}

.productline_imperial.j45-h1,
.productline_imperial.j50-h1 {
  background-image: url('./imperial/h1-50/h1-50-j.png');
}

.productline_imperial.s45-h1,
.productline_imperial.s50-h1 {
  background-image: url('./imperial/h1-50/h1-50-s.png');
}

.productline_dialog.x45-h1,
.productline_dialog.x50-h1 {
  background-image: url('./dialog/h1-50/h1-50-x.png');
}

.productline_imperial.x45-h1,
.productline_imperial.x50-h1 {
  background-image: url('./imperial/h1-50/h1-50-x.png');
}

/* h2-100 */
.productline_imperial.hm90-h2,
.productline_imperial.hm100-h2 {
  background-image: url('./imperial/h2-100/h2-100-manual.png');
}

.productline_imperial.a90-h2,
.productline_imperial.a100-h2 {
  background-image: url('./imperial/h2-100/h2-100-a.png');
}

.productline_imperial.b90-h2,
.productline_imperial.b100-h2 {
  background-image: url('./imperial/h2-100/h2-100-b.png');
}

.productline_imperial.c90-h2,
.productline_imperial.c100-h2 {
  background-image: url('./imperial/h2-100/h2-100-c.png');
}

.productline_imperial.d90-h2,
.productline_imperial.d100-h2 {
  background-image: url('./imperial/h2-100/h2-100-d.png');
}

.productline_imperial.el90-h2,
.productline_imperial.el100-h2 {
  background-image: url('./imperial/h2-100/h2-100-el.png');
}

.productline_imperial.er90-h2,
.productline_imperial.er100-h2 {
  background-image: url('./imperial/h2-100/h2-100-er.png');
}

.productline_imperial.fl90-h2,
.productline_imperial.fl100-h2 {
  background-image: url('./imperial/h2-100/h2-100-fl.png');
}

.productline_imperial.fr90-h2,
.productline_imperial.fr100-h2 {
  background-image: url('./imperial/h2-100/h2-100-fr.png');
}

.productline_imperial.g90-h2,
.productline_imperial.g100-h2 {
  background-image: url('./imperial/h2-100/h2-100-g.png');
}

.productline_imperial.h90-h2,
.productline_imperial.h100-h2 {
  background-image: url('./imperial/h2-100/h2-100-h.png');
}

.productline_imperial.i90-h2,
.productline_imperial.i100-h2 {
  background-image: url('./imperial/h2-100/h2-100-i.png');
}

.productline_imperial.j90-h2,
.productline_imperial.j100-h2 {
  background-image: url('./imperial/h2-100/h2-100-j.png');
}

.productline_imperial.s90-h2,
.productline_imperial.s100-h2 {
  background-image: url('./imperial/h2-100/h2-100-s.png');
}

/* h2-50 */
.productline_imperial.hm45-h2,
.productline_imperial.hm50-h2 {
  background-image: url('./imperial/h2-50/h2-50-manual.png');
}

.productline_imperial.a45-h2,
.productline_imperial.a50-h2 {
  background-image: url('./imperial/h2-50/h2-50-a.png');
}

.productline_imperial.b45-h2,
.productline_imperial.b50-h2 {
  background-image: url('./imperial/h2-50/h2-50-b.png');
}

.productline_imperial.c45-h2,
.productline_imperial.c50-h2 {
  background-image: url('./imperial/h2-50/h2-50-c.png');
}

.productline_imperial.d45-h2,
.productline_imperial.d50-h2 {
  background-image: url('./imperial/h2-50/h2-50-d.png');
}

.productline_imperial.g45-h2,
.productline_imperial.g50-h2 {
  background-image: url('./imperial/h2-50/h2-50-g.png');
}

.productline_imperial.i45-h2,
.productline_imperial.i50-h2 {
  background-image: url('./imperial/h2-50/h2-50-i.png');
}

.productline_imperial.j45-h2,
.productline_imperial.j50-h2 {
  background-image: url('./imperial/h2-50/h2-50-j.png');
}

.productline_imperial.s45-h2,
.productline_imperial.s50-h2 {
  background-image: url('./imperial/h2-50/h2-50-s.png');
}

.productline_imperial.x45-h2,
.productline_imperial.x50-h2 {
  background-image: url('./imperial/h2-50/h2-50-x.png');
}

/* Productlijn Dialog */
/* h1-100 */
.productline_dialog.hm90-h1,
.productline_dialog.hm100-h1 {
  background-image: url('./dialog/h1-100/h1-100-manual.png');
}

.productline_dialog.a90-h1,
.productline_dialog.a100-h1 {
  background-image: url('./dialog/h1-100/h1-100-a.png');
}

.productline_dialog.b90-h1,
.productline_dialog.b100-h1 {
  background-image: url('./dialog/h1-100/h1-100-b.png');
}

.productline_dialog.c90-h1,
.productline_dialog.c100-h1 {
  background-image: url('./dialog/h1-100/h1-100-c.png');
}

.productline_dialog.d90-h1,
.productline_dialog.d100-h1 {
  background-image: url('./dialog/h1-100/h1-100-d.png');
}

.productline_dialog.el90-h1,
.productline_dialog.el100-h1 {
  background-image: url('./dialog/h1-100/h1-100-el.png');
}

.productline_dialog.er90-h1,
.productline_dialog.er100-h1 {
  background-image: url('./dialog/h1-100/h1-100-er.png');
}

.productline_dialog.fl90-h1,
.productline_dialog.fl100-h1 {
  background-image: url('./dialog/h1-100/h1-100-fl.png');
}

.productline_dialog.fr90-h1,
.productline_dialog.fr100-h1 {
  background-image: url('./dialog/h1-100/h1-100-fr.png');
}

.productline_dialog.g90-h1,
.productline_dialog.g100-h1 {
  background-image: url('./dialog/h1-100/h1-100-g.png');
}

.productline_dialog.h90-h1,
.productline_dialog.h100-h1 {
  background-image: url('./dialog/h1-100/h1-100-h.png');
}

.productline_dialog.hl90-h1,
.productline_dialog.hl100-h1 {
  background-image: url('./dialog/h1-100/h1-100-hl.png');
}

.productline_dialog.hr90-h1,
.productline_dialog.hr100-h1 {
  background-image: url('./dialog/h1-100/h1-100-hr.png');
}

.productline_dialog.i90-h1,
.productline_dialog.i100-h1 {
  background-image: url('./dialog/h1-100/h1-100-i.png');
}

.productline_dialog.j90-h1,
.productline_dialog.j100-h1 {
  background-image: url('./dialog/h1-100/h1-100-j.png');
}

.productline_dialog.s90-h1,
.productline_dialog.s100-h1 {
  background-image: url('./dialog/h1-100/h1-100-s.png');
}

/* h1-50 */
.productline_dialog.hm45-h1,
.productline_dialog.hm50-h1 {
  background-image: url('./dialog/h1-50/h1-50-manual.png');
}

.productline_dialog.a45-h1,
.productline_dialog.a50-h1 {
  background-image: url('./dialog/h1-50/h1-50-a.png');
}

.productline_dialog.b45-h1,
.productline_dialog.b50-h1 {
  background-image: url('./dialog/h1-50/h1-50-b.png');
}

.productline_dialog.c45-h1,
.productline_dialog.c50-h1 {
  background-image: url('./dialog/h1-50/h1-50-c.png');
}

.productline_dialog.d45-h1,
.productline_dialog.d50-h1 {
  background-image: url('./dialog/h1-50/h1-50-d.png');
}

.productline_dialog.g45-h1,
.productline_dialog.g50-h1 {
  background-image: url('./dialog/h1-50/h1-50-g.png');
}

.productline_dialog.h45-h1,
.productline_dialog.h50-h1 {
  background-image: url('./dialog/h1-50/h1-50-h.png');
}

.productline_dialog.i45-h1,
.productline_dialog.i50-h1 {
  background-image: url('./dialog/h1-50/h1-50-i.png');
}

.productline_dialog.j45-h1,
.productline_dialog.j50-h1 {
  background-image: url('./dialog/h1-50/h1-50-j.png');
}

.productline_dialog.s45-h1,
.productline_dialog.s50-h1 {
  background-image: url('./dialog/h1-50/h1-50-s.png');
}

.productline_dialog.x45-h1,
.productline_dialog.x50-h1 {
  background-image: url('./dialog/h1-50/h1-50-x.png');
}

/* h2-66 */
.productline_dialog.hm66-h2 {
  background-image: url('./dialog/h2-66/h2-66-manual.png');
}

.productline_dialog.a66-h2 {
  background-image: url('./dialog/h2-66/h2-66-a.png');
}

.productline_dialog.b66-h2 {
  background-image: url('./dialog/h2-66/h2-66-b.png');
}

.productline_dialog.c66-h2 {
  background-image: url('./dialog/h2-66/h2-66-c.png');
}

.productline_dialog.d66-h2 {
  background-image: url('./dialog/h2-66/h2-66-d.png');
}

.productline_dialog.dl66-h2 {
  background-image: url('./dialog/h2-66/h2-66-dl.png');
}

.productline_dialog.dr66-h2 {
  background-image: url('./dialog/h2-66/h2-66-dr.png');
}

.productline_dialog.el66-h2 {
  background-image: url('./dialog/h2-66/h2-66-el.png');
}

.productline_dialog.er66-h2 {
  background-image: url('./dialog/h2-66/h2-66-er.png');
}

.productline_dialog.fl66-h2 {
  background-image: url('./dialog/h2-66/h2-66-fl.png');
}

.productline_dialog.fr66-h2 {
  background-image: url('./dialog/h2-66/h2-66-fr.png');
}

.productline_dialog.s66-h2 {
  background-image: url('./dialog/h2-66/h2-66-s.png');
}

.productline_dialog.xl66-h2 {
  background-image: url('./dialog/h2-66/h2-66-xl.png');
}

.productline_dialog.xr66-h2 {
  background-image: url('./dialog/h2-66/h2-66-xr.png');
}

/* h2-100 */
.productline_dialog.hm90-h2,
.productline_dialog.hm100-h2 {
  background-image: url('./dialog/h2-100/h2-100-manual.png');
}

.productline_dialog.a90-h2,
.productline_dialog.a100-h2 {
  background-image: url('./dialog/h2-100/h2-100-a.png');
}

.productline_dialog.b90-h2,
.productline_dialog.b100-h2 {
  background-image: url('./dialog/h2-100/h2-100-b.png');
}

.productline_dialog.c90-h2,
.productline_dialog.c100-h2 {
  background-image: url('./dialog/h2-100/h2-100-c.png');
}

.productline_dialog.d90-h2,
.productline_dialog.d100-h2 {
  background-image: url('./dialog/h2-100/h2-100-d.png');
}

.productline_dialog.dl90-h2,
.productline_dialog.dl100-h2 {
  background-image: url('./dialog/h2-100/h2-100-dl.png');
}

.productline_dialog.dr90-h2,
.productline_dialog.dr100-h2 {
  background-image: url('./dialog/h2-100/h2-100-dr.png');
}

.productline_dialog.el90-h2,
.productline_dialog.el100-h2 {
  background-image: url('./dialog/h2-100/h2-100-el.png');
}

.productline_dialog.er90-h2,
.productline_dialog.er100-h2 {
  background-image: url('./dialog/h2-100/h2-100-er.png');
}

.productline_dialog.fl90-h2,
.productline_dialog.fl100-h2 {
  background-image: url('./dialog/h2-100/h2-100-fl.png');
}

.productline_dialog.fr90-h2,
.productline_dialog.fr100-h2 {
  background-image: url('./dialog/h2-100/h2-100-fr.png');
}

.productline_dialog.g90-h2,
.productline_dialog.g100-h2 {
  background-image: url('./dialog/h2-100/h2-100-g.png');
}

.productline_dialog.h90-h2,
.productline_dialog.h100-h2 {
  background-image: url('./dialog/h2-100/h2-100-h.png');
}

.productline_dialog.hl90-h2,
.productline_dialog.hl100-h2 {
  background-image: url('./dialog/h2-100/h2-100-hl.png');
}

.productline_dialog.hr90-h2,
.productline_dialog.hr100-h2 {
  background-image: url('./dialog/h2-100/h2-100-hr.png');
}

.productline_dialog.i90-h2,
.productline_dialog.i100-h2 {
  background-image: url('./dialog/h2-100/h2-100-i.png');
}

.productline_dialog.j90-h2,
.productline_dialog.j100-h2 {
  background-image: url('./dialog/h2-100/h2-100-j.png');
}

.productline_dialog.jl90-h2,
.productline_dialog.jl100-h2 {
  background-image: url('./dialog/h2-100/h2-100-jl.png');
}

.productline_dialog.jr90-h2,
.productline_dialog.jr100-h2 {
  background-image: url('./dialog/h2-100/h2-100-jr.png');
}

.productline_dialog.kl90-h2,
.productline_dialog.kl100-h2 {
  background-image: url('./dialog/h2-100/h2-100-kl.png');
}

.productline_dialog.kr90-h2,
.productline_dialog.kr100-h2 {
  background-image: url('./dialog/h2-100/h2-100-kr.png');
}

.productline_dialog.n90-h2,
.productline_dialog.n100-h2 {
  background-image: url('./dialog/h2-100/h2-100-n.png');
}

.productline_dialog.s90-h2,
.productline_dialog.s100-h2 {
  background-image: url('./dialog/h2-100/h2-100-s.png');
}

.productline_dialog.x90-h2,
.productline_dialog.x100-h2 {
  background-image: url('./dialog/h2-100/h2-100-x.png');
}


/* h2-50 */
.productline_dialog.hm45-h2,
.productline_dialog.hm50-h2 {
  background-image: url('./dialog/h2-50/h2-50-manual.png');
}

.productline_dialog.a45-h2,
.productline_dialog.a50-h2 {
  background-image: url('./dialog/h2-50/h2-50-a.png');
}

.productline_dialog.b45-h2,
.productline_dialog.b50-h2 {
  background-image: url('./dialog/h2-50/h2-50-b.png');
}

.productline_dialog.c45-h2,
.productline_dialog.c50-h2 {
  background-image: url('./dialog/h2-50/h2-50-c.png');
}

.productline_dialog.d45-h2,
.productline_dialog.d50-h2 {
  background-image: url('./dialog/h2-50/h2-50-d.png');
}

.productline_dialog.g45-h2,
.productline_dialog.g50-h2 {
  background-image: url('./dialog/h2-50/h2-50-g.png');
}

.productline_dialog.h45-h2,
.productline_dialog.h50-h2 {
  background-image: url('./dialog/h2-50/h2-50-h.png');
}

.productline_dialog.i45-h2,
.productline_dialog.i50-h2 {
  background-image: url('./dialog/h2-50/h2-50-i.png');
}

.productline_dialog.j45-h2,
.productline_dialog.j50-h2 {
  background-image: url('./dialog/h2-50/h2-50-j.png');
}

.productline_dialog.s45-h2,
.productline_dialog.s50-h2 {
  background-image: url('./dialog/h2-50/h2-50-s.png');
}

.productline_dialog.x45-h2,
.productline_dialog.x50-h2 {
  background-image: url('./dialog/h2-50/h2-50-x.png');
}

/* Productlijn Select-art */
/* h1-100 */
.productline_selectart.hm90-h1,
.productline_selectart.hm100-h1 {
  background-image: url('./selectart/h1-100/h1-100-manual.png');
}

.productline_selectart.a90-h1,
.productline_selectart.a100-h1 {
  background-image: url('./selectart/h1-100/h1-100-a.png');
}

.productline_selectart.b90-h1,
.productline_selectart.b100-h1 {
  background-image: url('./selectart/h1-100/h1-100-b.png');
}

.productline_selectart.c90-h1,
.productline_selectart.c100-h1 {
  background-image: url('./selectart/h1-100/h1-100-c.png');
}

.productline_selectart.d90-h1,
.productline_selectart.d100-h1 {
  background-image: url('./selectart/h1-100/h1-100-d.png');
}

.productline_selectart.el90-h1,
.productline_selectart.el100-h1 {
  background-image: url('./selectart/h1-100/h1-100-el.png');
}

.productline_selectart.er90-h1,
.productline_selectart.er100-h1 {
  background-image: url('./selectart/h1-100/h1-100-er.png');
}

.productline_selectart.fl90-h1,
.productline_selectart.fl100-h1 {
  background-image: url('./selectart/h1-100/h1-100-fl.png');
}

.productline_selectart.fr90-h1,
.productline_selectart.fr100-h1 {
  background-image: url('./selectart/h1-100/h1-100-fr.png');
}

.productline_selectart.g90-h1,
.productline_selectart.g100-h1 {
  background-image: url('./selectart/h1-100/h1-100-g.png');
}

.productline_selectart.h90-h1,
.productline_selectart.h100-h1 {
  background-image: url('./selectart/h1-100/h1-100-h.png');
}

.productline_selectart.i90-h1,
.productline_selectart.i100-h1 {
  background-image: url('./selectart/h1-100/h1-100-i.png');
}

.productline_selectart.j90-h1,
.productline_selectart.j100-h1 {
  background-image: url('./selectart/h1-100/h1-100-j.png');
}

.productline_selectart.s90-h1,
.productline_selectart.s100-h1 {
  background-image: url('./selectart/h1-100/h1-100-s.png');
}

/* h1-50 */
.productline_selectart.hm45-h1,
.productline_selectart.hm50-h1 {
  background-image: url('./selectart/h1-50/h1-50-manual.png');
}

.productline_selectart.a45-h1,
.productline_selectart.a50-h1 {
  background-image: url('./selectart/h1-50/h1-50-a.png');
}

.productline_selectart.b45-h1,
.productline_selectart.b50-h1 {
  background-image: url('./selectart/h1-50/h1-50-b.png');
}

.productline_selectart.c45-h1,
.productline_selectart.c50-h1 {
  background-image: url('./selectart/h1-50/h1-50-c.png');
}

.productline_selectart.d45-h1,
.productline_selectart.d50-h1 {
  background-image: url('./selectart/h1-50/h1-50-d.png');
}

.productline_selectart.g45-h1,
.productline_selectart.g50-h1 {
  background-image: url('./selectart/h1-50/h1-50-g.png');
}

.productline_selectart.i45-h1,
.productline_selectart.i50-h1 {
  background-image: url('./selectart/h1-50/h1-50-i.png');
}

.productline_selectart.j45-h1,
.productline_selectart.j50-h1 {
  background-image: url('./selectart/h1-50/h1-50-j.png');
}

.productline_selectart.s45-h1,
.productline_selectart.s50-h1 {
  background-image: url('./selectart/h1-50/h1-50-s.png');
}

.productline_selectart.x45-h1,
.productline_selectart.x50-h1 {
  background-image: url('./selectart/h1-50/h1-50-x.png');
}

/* h2-100 */
.productline_selectart.hm90-h2,
.productline_selectart.hm100-h2 {
  background-image: url('./selectart/h2-100/h2-100-manual.png');
}

.productline_selectart.a90-h2,
.productline_selectart.a100-h2 {
  background-image: url('./selectart/h2-100/h2-100-a.png');
}

.productline_selectart.b90-h2,
.productline_selectart.b100-h2 {
  background-image: url('./selectart/h2-100/h2-100-b.png');
}

.productline_selectart.c90-h2,
.productline_selectart.c100-h2 {
  background-image: url('./selectart/h2-100/h2-100-c.png');
}

.productline_selectart.d90-h2,
.productline_selectart.d100-h2 {
  background-image: url('./selectart/h2-100/h2-100-d.png');
}

.productline_selectart.el90-h2,
.productline_selectart.el100-h2 {
  background-image: url('./selectart/h2-100/h2-100-el.png');
}

.productline_selectart.er90-h2,
.productline_selectart.er100-h2 {
  background-image: url('./selectart/h2-100/h2-100-er.png');
}

.productline_selectart.fl90-h2,
.productline_selectart.fl100-h2 {
  background-image: url('./selectart/h2-100/h2-100-fl.png');
}

.productline_selectart.fr90-h2,
.productline_selectart.fr100-h2 {
  background-image: url('./selectart/h2-100/h2-100-fr.png');
}

.productline_selectart.g90-h2,
.productline_selectart.g100-h2 {
  background-image: url('./selectart/h2-100/h2-100-g.png');
}

.productline_selectart.h90-h2,
.productline_selectart.h100-h2 {
  background-image: url('./selectart/h2-100/h2-100-h.png');
}

.productline_selectart.i90-h2,
.productline_selectart.i100-h2 {
  background-image: url('./selectart/h2-100/h2-100-i.png');
}

.productline_selectart.j90-h2,
.productline_selectart.j100-h2 {
  background-image: url('./selectart/h2-100/h2-100-j.png');
}

.productline_selectart.s90-h2,
.productline_selectart.s100-h2 {
  background-image: url('./selectart/h2-100/h2-100-s.png');
}

/* h2-50 */
.productline_selectart.hm45-h2,
.productline_selectart.hm50-h2 {
  background-image: url('./selectart/h2-50/h2-50-manual.png');
}

.productline_selectart.a45-h2,
.productline_selectart.a50-h2 {
  background-image: url('./selectart/h2-50/h2-50-a.png');
}

.productline_selectart.b45-h2,
.productline_selectart.b50-h2 {
  background-image: url('./selectart/h2-50/h2-50-b.png');
}

.productline_selectart.c45-h2,
.productline_selectart.c50-h2 {
  background-image: url('./selectart/h2-50/h2-50-c.png');
}

.productline_selectart.d45-h2,
.productline_selectart.d50-h2 {
  background-image: url('./selectart/h2-50/h2-50-d.png');
}

.productline_selectart.g45-h2,
.productline_selectart.g50-h2 {
  background-image: url('./selectart/h2-50/h2-50-g.png');
}

.productline_selectart.i45-h2,
.productline_selectart.i50-h2 {
  background-image: url('./selectart/h2-50/h2-50-i.png');
}

.productline_selectart.j45-h2,
.productline_selectart.j50-h2 {
  background-image: url('./selectart/h2-50/h2-50-j.png');
}

.productline_selectart.s45-h2,
.productline_selectart.s50-h2 {
  background-image: url('./selectart/h2-50/h2-50-s.png');
}

.productline_selectart.x45-h2,
.productline_selectart.x50-h2 {
  background-image: url('./selectart/h2-50/h2-50-x.png');
}

/* Productlijn YourJoyce */
/* j2-50 */
.productline_yourjoyce.hm50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-manual.png');
}

.productline_yourjoyce.a50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-a.png');
}

.productline_yourjoyce.b50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-b.png');
}

.productline_yourjoyce.c50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-c.png');
}

.productline_yourjoyce.d50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-d.png');
}

.productline_yourjoyce.g50-j2 {
  background-image: url('./dialog/h2-50/h2-50-g.png');
}

.productline_yourjoyce.h50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-h.png');
}

.productline_yourjoyce.j50-j2 {
  background-image: url('./dialog/h2-50/h2-50-j.png');
}

.productline_yourjoyce.m50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-m.png');
}

.productline_yourjoyce.p50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-p.png');
}

.productline_yourjoyce.s50-j2 {
  background-image: url('./yourjoyce/j2-50/j2-50-s.png');
}

.productline_yourjoyce.x50-j2 {
  background-image: url('./dialog/h2-50/h2-50-x.png');
}

/* j2-75 */
.productline_yourjoyce.hm75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-manual.png');
}

.productline_yourjoyce.a75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-a.png');
}

.productline_yourjoyce.b75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-b.png');
}

.productline_yourjoyce.c75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-c.png');
}

.productline_yourjoyce.d75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-d.png');
}

.productline_yourjoyce.s75-j2 {
  background-image: url('./yourjoyce/j2-75/j2-75-s.png');
}


/* j2-100 */
.productline_yourjoyce.hm100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-manual.png');
}

.productline_yourjoyce.a100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-a.png');
}

.productline_yourjoyce.b100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-b.png');
}

.productline_yourjoyce.c100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-c.png');
}

.productline_yourjoyce.d100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-d.png');
}

.productline_yourjoyce.el100-j2 {
  background-image: url('./dialog/h2-100/h2-100-el.png');
}

.productline_yourjoyce.er100-j2 {
  background-image: url('./dialog/h2-100/h2-100-er.png');
}

.productline_yourjoyce.fl100-j2 {
  background-image: url('./dialog/h2-100/h2-100-fl.png');
}

.productline_yourjoyce.fr100-j2 {
  background-image: url('./dialog/h2-100/h2-100-fr.png');
}

.productline_yourjoyce.g100-j2 {
  background-image: url('./dialog/h2-100/h2-100-g.png');
}

.productline_yourjoyce.j100-j2 {
  background-image: url('./dialog/h2-100/h2-100-j.png');
}

.productline_yourjoyce.ml100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-ml.png');
}

.productline_yourjoyce.mr100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-mr.png');
}

.productline_yourjoyce.pl100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-pl.png');
}

.productline_yourjoyce.pr100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-pr.png');
}

.productline_yourjoyce.s100-j2 {
  background-image: url('./yourjoyce/j2-100/j2-100-s.png');
}

/* Productlijn Steffen */
/* h2-50 */
.productline_steffen.hm45-h2,
.productline_steffen.hm50-h2 {
  background-image: url('./steffen/h2-50/h2-50-manual.png');
}

.productline_steffen.a45-h2,
.productline_steffen.a50-h2 {
  background-image: url('./steffen/h2-50/h2-50-a.png');
}

.productline_steffen.b45-h2,
.productline_steffen.b50-h2 {
  background-image: url('./steffen/h2-50/h2-50-b.png');
}

.productline_steffen.c45-h2,
.productline_steffen.c50-h2 {
  background-image: url('./steffen/h2-50/h2-50-c.png');
}

.productline_steffen.d45-h2,
.productline_steffen.d50-h2 {
  background-image: url('./steffen/h2-50/h2-50-d.png');
}

.productline_steffen.g45-h2,
.productline_steffen.g50-h2 {
  background-image: url('./steffen/h2-50/h2-50-g.png');
}

.productline_steffen.i45-h2,
.productline_steffen.i50-h2 {
  background-image: url('./steffen/h2-50/h2-50-i.png');
}

.productline_steffen.j45-h2,
.productline_steffen.j50-h2 {
  background-image: url('./steffen/h2-50/h2-50-j.png');
}

.productline_steffen.s45-h2,
.productline_steffen.s50-h2 {
  background-image: url('./steffen/h2-50/h2-50-s.png');
}

.productline_steffen.x45-h2,
.productline_steffen.x50-h2 {
  background-image: url('./steffen/h2-50/h2-50-x.png');
}

/* h2-60 */
.productline_steffen.hm60-h2 {
  background-image: url('./steffen/h2-60/h2-60-manual.png');
}

.productline_steffen.a60-h2 {
  background-image: url('./steffen/h2-60/h2-60-a.png');
}

.productline_steffen.b60-h2 {
  background-image: url('./steffen/h2-60/h2-60-b.png');
}

.productline_steffen.c60-h2 {
  background-image: url('./steffen/h2-60/h2-60-c.png');
}

.productline_steffen.d60-h2 {
  background-image: url('./steffen/h2-60/h2-60-d.png');
}

.productline_steffen.g60-h2 {
  background-image: url('./steffen/h2-60/h2-60-g.png');
}

.productline_steffen.i60-h2 {
  background-image: url('./steffen/h2-60/h2-60-i.png');
}

.productline_steffen.j60-h2 {
  background-image: url('./steffen/h2-60/h2-60-j.png');
}

.productline_steffen.s60-h2 {
  background-image: url('./steffen/h2-60/h2-60-s.png');
}

.productline_steffen.x60-h2 {
  background-image: url('./steffen/h2-60/h2-60-x.png');
}

/* h2-80 */
.productline_steffen.hm80-h2 {
  background-image: url('./steffen/h2-80/h2-80-manual.png');
}

.productline_steffen.a80-h2 {
  background-image: url('./steffen/h2-80/h2-80-a.png');
}

.productline_steffen.b80-h2 {
  background-image: url('./steffen/h2-80/h2-80-b.png');
}

.productline_steffen.c80-h2 {
  background-image: url('./steffen/h2-80/h2-80-c.png');
}

.productline_steffen.d80-h2 {
  background-image: url('./steffen/h2-80/h2-80-d.png');
}

.productline_steffen.el80-h2 {
  background-image: url('./steffen/h2-80/h2-80-el.png');
}

.productline_steffen.er80-h2 {
  background-image: url('./steffen/h2-80/h2-80-er.png');
}

.productline_steffen.g80-h2 {
  background-image: url('./steffen/h2-80/h2-80-g.png');
}

.productline_steffen.i80-h2 {
  background-image: url('./steffen/h2-80/h2-80-i.png');
}

.productline_steffen.j80-h2 {
  background-image: url('./steffen/h2-80/h2-80-j.png');
}

.productline_steffen.ll80-h2 {
  background-image: url('./steffen/h2-80/h2-80-ll.png');
}

.productline_steffen.lr80-h2 {
  background-image: url('./steffen/h2-80/h2-80-lr.png');
}

.productline_steffen.nl80-h2 {
  background-image: url('./steffen/h2-80/h2-80-nl.png');
}

.productline_steffen.nr80-h2 {
  background-image: url('./steffen/h2-80/h2-80-nr.png');
}

.productline_steffen.s80-h2 {
  background-image: url('./steffen/h2-80/h2-80-s.png');
}

.productline_steffen.tl80-h2 {
  background-image: url('./steffen/h2-80/h2-80-tl.png');
}

.productline_steffen.tr80-h2 {
  background-image: url('./steffen/h2-80/h2-80-tr.png');
}

.productline_steffen.x80-h2 {
  background-image: url('./steffen/h2-80/h2-80-x.png');
}

/* h2-100 */
.productline_steffen.hm90-h2,
.productline_steffen.hm100-h2 {
  background-image: url('./steffen/h2-100/h2-100-manual.png');
}

.productline_steffen.a90-h2,
.productline_steffen.a100-h2 {
  background-image: url('./steffen/h2-100/h2-100-a.png');
}

.productline_steffen.b90-h2,
.productline_steffen.b100-h2 {
  background-image: url('./steffen/h2-100/h2-100-b.png');
}

.productline_steffen.c90-h2,
.productline_steffen.c100-h2 {
  background-image: url('./steffen/h2-100/h2-100-c.png');
}

.productline_steffen.d90-h2,
.productline_steffen.d100-h2 {
  background-image: url('./steffen/h2-100/h2-100-d.png');
}

.productline_steffen.el90-h2,
.productline_steffen.el100-h2 {
  background-image: url('./steffen/h2-100/h2-100-el.png');
}

.productline_steffen.er90-h2,
.productline_steffen.er100-h2 {
  background-image: url('./steffen/h2-100/h2-100-er.png');
}

.productline_steffen.g90-h2,
.productline_steffen.g100-h2 {
  background-image: url('./steffen/h2-100/h2-100-g.png');
}

.productline_steffen.i90-h2,
.productline_steffen.i100-h2 {
  background-image: url('./steffen/h2-100/h2-100-i.png');
}

.productline_steffen.j90-h2,
.productline_steffen.j100-h2 {
  background-image: url('./steffen/h2-100/h2-100-j.png');
}

.productline_steffen.kl90-h2,
.productline_steffen.kl100-h2 {
  background-image: url('./steffen/h2-100/h2-100-kl.png');
}

.productline_steffen.kr90-h2,
.productline_steffen.kr100-h2 {
  background-image: url('./steffen/h2-100/h2-100-kr.png');
}

.productline_steffen.ll90-h2,
.productline_steffen.ll100-h2 {
  background-image: url('./steffen/h2-100/h2-100-ll.png');
}

.productline_steffen.lr90-h2,
.productline_steffen.lr100-h2 {
  background-image: url('./steffen/h2-100/h2-100-lr.png');
}

.productline_steffen.nl90-h2,
.productline_steffen.nl100-h2 {
  background-image: url('./steffen/h2-100/h2-100-nl.png');
}

.productline_steffen.nr90-h2,
.productline_steffen.nr100-h2 {
  background-image: url('./steffen/h2-100/h2-100-nr.png');
}

.productline_steffen.s90-h2,
.productline_steffen.s100-h2 {
  background-image: url('./steffen/h2-100/h2-100-s.png');
}

.productline_steffen.tl90-h2,
.productline_steffen.tl100-h2 {
  background-image: url('./steffen/h2-100/h2-100-tl.png');
}

.productline_steffen.tr90-h2,
.productline_steffen.tr100-h2 {
  background-image: url('./steffen/h2-100/h2-100-tr.png');
}

.productline_steffen.x90-h2,
.productline_steffen.x100-h2 {
  background-image: url('./steffen/h2-100/h2-100-x.png');
}

/* h2-120 */
.productline_steffen.hm120-h2 {
  background-image: url('./steffen/h2-120/h2-120-manual.png');
}

.productline_steffen.a120-h2 {
  background-image: url('./steffen/h2-120/h2-120-a.png');
}

.productline_steffen.b120-h2 {
  background-image: url('./steffen/h2-120/h2-120-b.png');
}

.productline_steffen.c120-h2 {
  background-image: url('./steffen/h2-120/h2-120-c.png');
}

.productline_steffen.d120-h2 {
  background-image: url('./steffen/h2-120/h2-120-d.png');
}

.productline_steffen.el120-h2 {
  background-image: url('./steffen/h2-120/h2-120-el.png');
}

.productline_steffen.er120-h2 {
  background-image: url('./steffen/h2-120/h2-120-er.png');
}

.productline_steffen.g120-h2 {
  background-image: url('./steffen/h2-120/h2-120-g.png');
}

.productline_steffen.i120-h2 {
  background-image: url('./steffen/h2-120/h2-120-i.png');
}

.productline_steffen.j120-h2 {
  background-image: url('./steffen/h2-120/h2-120-j.png');
}

.productline_steffen.ll120-h2 {
  background-image: url('./steffen/h2-120/h2-120-ll.png');
}

.productline_steffen.lr120-h2 {
  background-image: url('./steffen/h2-120/h2-120-lr.png');
}

.productline_steffen.nl120-h2 {
  background-image: url('./steffen/h2-120/h2-120-nl.png');
}

.productline_steffen.nr120-h2 {
  background-image: url('./steffen/h2-120/h2-120-nr.png');
}

.productline_steffen.s120-h2 {
  background-image: url('./steffen/h2-120/h2-120-s.png');
}

.productline_steffen.tl120-h2 {
  background-image: url('./steffen/h2-120/h2-120-tl.png');
}

.productline_steffen.tr120-h2 {
  background-image: url('./steffen/h2-120/h2-120-tr.png');
}

.productline_steffen.x120-h2 {
  background-image: url('./steffen/h2-120/h2-120-x.png');
}

/* Productlijn Wiemann A
/* h2-50 */
.productline_wiemanna.hm50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-manual.png');
}

.productline_wiemanna.a50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-a.png');
}

.productline_wiemanna.b50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-b.png');
}

.productline_wiemanna.c50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-c.png');
}

.productline_wiemanna.d50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-d.png');
}

.productline_wiemanna.g50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-g.png');
}

.productline_wiemanna.h50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-h.png');
}

.productline_wiemanna.i50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-i.png');
}

.productline_wiemanna.j50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-j.png');
}

.productline_wiemanna.s50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-s.png');
}

.productline_wiemanna.x50-h2 {
  background-image: url('./wiemanna/h2-50/h2-50-x.png');
}

/* h2-75 */
/* h2-80 */
.productline_wiemanna.hm75-h2,
.productline_wiemanna.hm80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-manual.png');
}

.productline_wiemanna.a75-h2,
.productline_wiemanna.a80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-a.png');
}

.productline_wiemanna.b75-h2,
.productline_wiemanna.b80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-b.png');
}

.productline_wiemanna.c75-h2,
.productline_wiemanna.c80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-c.png');
}

.productline_wiemanna.d75-h2,
.productline_wiemanna.d80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-d.png');
}

.productline_wiemanna.el75-h2,
.productline_wiemanna.el80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-el.png');
}

.productline_wiemanna.er75-h2,
.productline_wiemanna.er80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-er.png');
}

.productline_wiemanna.fl75-h2,
.productline_wiemanna.fl80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-fl.png');
}

.productline_wiemanna.fr75-h2,
.productline_wiemanna.fr80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-fr.png');
}

.productline_wiemanna.g75-h2,
.productline_wiemanna.g80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-g.png');
}

.productline_wiemanna.gl75-h2,
.productline_wiemanna.gl80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-gl.png');
}

.productline_wiemanna.gr75-h2,
.productline_wiemanna.gr80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-gr.png');
}

.productline_wiemanna.h75-h2,
.productline_wiemanna.h80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-h.png');
}

.productline_wiemanna.hl75-h2,
.productline_wiemanna.hl80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-hl.png');
}

.productline_wiemanna.hr75-h2,
.productline_wiemanna.hr80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-hr.png');
}

.productline_wiemanna.i75-h2,
.productline_wiemanna.i80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-i.png');
}

.productline_wiemanna.j75-h2,
.productline_wiemanna.j80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-j.png');
}

.productline_wiemanna.jl75-h2,
.productline_wiemanna.jl80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-jl.png');
}

.productline_wiemanna.jr75-h2,
.productline_wiemanna.jr80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-jr.png');
}

.productline_wiemanna.s75-h2,
.productline_wiemanna.s80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-s.png');
}

.productline_wiemanna.x75-h2,
.productline_wiemanna.x80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-x.png');
}

.productline_wiemanna.xl75-h2,
.productline_wiemanna.xl80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-xl.png');
}

.productline_wiemanna.xr75-h2,
.productline_wiemanna.xr80-h2 {
  background-image: url('./wiemanna/h2-75/h2-75-xr.png');
}

/* h2-100 */
.productline_wiemanna.hm100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-manual.png');
}

.productline_wiemanna.a100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-a.png');
}

.productline_wiemanna.b100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-b.png');
}

.productline_wiemanna.c100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-c.png');
}

.productline_wiemanna.d100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-d.png');
}

.productline_wiemanna.el100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-el.png');
}

.productline_wiemanna.er100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-er.png');
}

.productline_wiemanna.fl100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-fl.png');
}

.productline_wiemanna.fr100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-fr.png');
}

.productline_wiemanna.g100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-g.png');
}

.productline_wiemanna.gl100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-gl.png');
}

.productline_wiemanna.gr100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-gr.png');
}

.productline_wiemanna.h100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-h.png');
}

.productline_wiemanna.hl100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-hl.png');
}

.productline_wiemanna.hr100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-hr.png');
}

.productline_wiemanna.i100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-i.png');
}

.productline_wiemanna.j100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-j.png');
}

.productline_wiemanna.jl100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-jl.png');
}

.productline_wiemanna.jr100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-jr.png');
}

.productline_wiemanna.s100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-s.png');
}

.productline_wiemanna.x100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-x.png');
}

.productline_wiemanna.xl100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-xl.png');
}

.productline_wiemanna.xr100-h2 {
  background-image: url('./wiemanna/h2-100/h2-100-xr.png');
}
