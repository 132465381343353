.contactform__header {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
.contactform__header > *:last-child {
  margin-bottom: 0;
}
.contactform__header::after {
  content: "";
  display: block;
  width: 66.66%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #dee1e1;
}
.contactform__header img {
  width: 75%;
  max-width: 300px;
  height: auto;
  vertical-align: bottom;
  margin-bottom: 15px;
}
.contactform .wh-form input, .contactform .wh-form textarea {
  border-radius: 5px;
}